import VerticalDivider from "components/VerticalDivider/VerticalDivider";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import useTotalFilterCount from "hooks/useTotalFilterCount";
import { FilterIcon, SortIcon, EyeIcon } from "icons";
import { FC, useCallback, useMemo } from "react";
import { selectActivePanel } from "store/selectors/controlPanelSelectors";
import { isInInitialState } from "store/selectors/fieldVisibilitySelectors";
import { setActivePanel } from "store/slices/controlPanelSlice";
import { COLLECTION_CONTROL_PANEL } from "constants/index";
import { getSavedSortableFields } from "store/selectors/sortingSelectors";

import styles from "./ToolsPanel.module.scss";

const ToolsPanel: FC = () => {
  const dispatch = useAppDispatch();

  const {
    body: { savedFilters: filters },
  } = useAppSelector((state) => state.collection.filters);

  const totalFilterCount = useTotalFilterCount(filters);

  const activePanel = useAppSelector(selectActivePanel);

  const isFiltersOpen = useMemo(
    () => activePanel === COLLECTION_CONTROL_PANEL.FILTERS,
    [activePanel]
  );

  const openFilterBar = useCallback(() => {
    if (activePanel === null) {
      dispatch(setActivePanel(COLLECTION_CONTROL_PANEL.FILTERS));
      return;
    }

    if (isFiltersOpen) {
      dispatch(setActivePanel(null));
    } else {
      dispatch(setActivePanel(null));
      setTimeout(
        () => dispatch(setActivePanel(COLLECTION_CONTROL_PANEL.FILTERS)),
        150
      );
    }
  }, [isFiltersOpen, activePanel, dispatch]);

  const isFieldVisibilityOpen = useMemo(
    () => activePanel === COLLECTION_CONTROL_PANEL.VISIBILITY,
    [activePanel]
  );

  const toggleFieldVisibilityBar = useCallback(() => {
    if (activePanel === null) {
      dispatch(setActivePanel(COLLECTION_CONTROL_PANEL.VISIBILITY));
      return;
    }

    if (isFieldVisibilityOpen) {
      dispatch(setActivePanel(null));
    } else {
      dispatch(setActivePanel(null));
      setTimeout(
        () => dispatch(setActivePanel(COLLECTION_CONTROL_PANEL.VISIBILITY)),
        150
      );
    }
  }, [isFieldVisibilityOpen, activePanel, dispatch]);

  const isFieldVisibilityInInitialState = useAppSelector(isInInitialState);

  const isSortingPanelOpen = useMemo(
    () => activePanel === COLLECTION_CONTROL_PANEL.SORTING,
    [activePanel]
  );

  const openSortingPanel = useCallback(() => {
    if (activePanel === null) {
      dispatch(setActivePanel(COLLECTION_CONTROL_PANEL.SORTING));
      return;
    }

    if (isSortingPanelOpen) {
      dispatch(setActivePanel(null));
    } else {
      dispatch(setActivePanel(null));
      setTimeout(
        () => dispatch(setActivePanel(COLLECTION_CONTROL_PANEL.SORTING)),
        150
      );
    }
  }, [isSortingPanelOpen, activePanel, dispatch]);

  const totalSortingVariantsCount = useAppSelector(
    getSavedSortableFields
  ).length;

  return (
    <>
      <div
        className={`${styles["button"]}${
          isFiltersOpen
            ? ` ${styles["button_active"]}`
            : `${
                totalFilterCount > 0
                  ? ` ${styles["button_default-with-counter"]}`
                  : ""
              }`
        }`}
        onClick={openFilterBar}
      >
        <FilterIcon />
        {totalFilterCount > 0 && (
          <div className={styles["counter-wrapper"]}>
            <VerticalDivider className={styles["button-divider"]} />{" "}
            {totalFilterCount}
          </div>
        )}
      </div>
      <div
        className={`${styles["button"]}${
          isSortingPanelOpen
            ? ` ${styles["button_active"]}`
            : `${
                totalSortingVariantsCount > 0
                  ? ` ${styles["button_default-with-counter"]}`
                  : ""
              }`
        }`}
        onClick={openSortingPanel}
      >
        <SortIcon />
        {totalSortingVariantsCount > 0 && (
          <div className={styles["counter-wrapper"]}>
            <VerticalDivider className={styles["button-divider"]} />{" "}
            {totalSortingVariantsCount}
          </div>
        )}
      </div>
      <div
        className={`${styles["button"]} ${styles["button_with-margin-right"]}${
          isFieldVisibilityOpen
            ? ` ${styles["button_active"]}`
            : `${
                !isFieldVisibilityInInitialState
                  ? ` ${styles["button_default-with-counter"]}`
                  : ""
              }`
        }`}
        onClick={toggleFieldVisibilityBar}
      >
        <EyeIcon />
      </div>
    </>
  );
};

export default ToolsPanel;
