import { useCallback } from "react";
import { openModal, closeModal } from "store/slices/modalSlice";
import { isModalOpen } from "store/selectors/modalSelectors";
import { ModalMeta } from "./../../typings/modal";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";

/**
 * Hook for opening and closing modals.
 * @param {string} modalFileName - The name of the modal file.
 * @returns {useModalProps} The modal state and methods for opening and closing the modal.
 */
interface useModalProps {
  isOpen: boolean;
  onOpen: (meta: ModalMeta) => void;
  onClose: () => void;
}

export function useModal(modalFileName: string): useModalProps {
  const dispatch = useAppDispatch();

  const onOpen = useCallback(
    (meta: ModalMeta) => dispatch(openModal({ modalFileName, meta })),
    [modalFileName, dispatch]
  );
  const onClose = useCallback(
    () => dispatch(closeModal({ modalFileName })),
    [modalFileName, dispatch]
  );

  const isOpen = useAppSelector((state) => isModalOpen(state, modalFileName));

  return {
    isOpen,
    onOpen,
    onClose,
  };
}
