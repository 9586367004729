import { VISIBILITY_FIELD_TYPES } from "constants/index";
import type { Field } from "typings/filter";

/**
 * Filters an array of fields and returns only the fields suitable for visibility.
 * @param fields - The array of fields to filter.
 * @returns An array of fields types suitable for visibility.
 */
export const getVisibilityFieldTypes = (fields: Field[]): Field[] => {
  return fields.filter((field) => VISIBILITY_FIELD_TYPES.includes(field.type));
};
