import { FC, useCallback, useEffect, useMemo } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";
import { setFilterValue } from "store/slices/filterSlice";
import { CommonFilter } from "typings/filter";

import styles from "./FilterBoolean.module.scss";

interface FilterBooleanProps {
  filter: CommonFilter;
}

const FilterBoolean: FC<FilterBooleanProps> = ({ filter }) => {
  const dispatch = useAppDispatch();

  const handleToggle = useCallback(
    (value: string) => dispatch(setFilterValue({ filter, value: value })),
    [dispatch, filter]
  );

  const options = useMemo(
    () => [
      { id: "true", label: "True" },
      { id: "false", label: "False" },
    ],
    []
  );

  useEffect(() => {
    if (!filter.value) {
      handleToggle(options[0].id);
    }
  }, [filter.value, handleToggle, options]);

  return (
    <div className={styles["wrapper"]}>
      <ToggleSwitch
        options={options}
        selectedOption={
          typeof filter.value === "string" ? filter.value : options[0].id
        }
        onToggle={handleToggle}
        variant="inverted"
      />
    </div>
  );
};

export default FilterBoolean;
