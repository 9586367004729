type OperatorString =
  | "is"
  | "is-not"
  | "contain"
  | "not-contain"
  | "is-empty"
  | "is-not-empty"
  | "lt"
  | "gt"
  | "lte"
  | "gte";

export const determineFilterOptionFromAPIOperator = (
  operatorString: OperatorString,
  fieldType: string
) => {
  switch (operatorString) {
    case "is":
      if (fieldType === "number") {
        return "=";
      } else {
        return "Is";
      }
    case "is-not":
      if (fieldType === "number") {
        return "≠";
      } else {
        return "Is Not";
      }
    case "contain":
      return "Contain";
    case "not-contain":
      return "Doesn't Contain";
    case "is-empty":
      return "Is Empty";
    case "is-not-empty":
      return "Is Not Empty";
    case "lt":
      return "<";
    case "gt":
      return ">";
    case "lte":
      return "≤";
    case "gte":
      return "≥";
    default:
      return "";
  }
};
