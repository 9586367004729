import { FC } from "react";
import { NavLink } from "react-router-dom";
import SubMenu from "components/Menu/SubMenu";
import Button from "components/Button/Button";
import { determineIcon } from "utils/determineIcon";
import { useAppSelector } from "hooks/useAppSelector";

import styles from "./Menu.module.scss";

const COMPONENT_FOLDER = "component-folder";

interface DynamicMenu {
  name: string;
  link: string;
  icon: JSX.Element;
  path: string;
  children?: DynamicMenu[];
}

const DynamicMenu: FC = () => {
  const dynamicMenu = useAppSelector((state) => state.menu.menu);

  return dynamicMenu?.map((item: any, index: number) => {
    if (
      item.isFolder &&
      item.children?.length === 0 &&
      item.type !== COMPONENT_FOLDER
    ) {
      return null;
    }

    if (item.type === COMPONENT_FOLDER || item.children?.length > 0) {
      return (
        <SubMenu
          icon={determineIcon(item.type)}
          key={item.slug || item.name}
          menuName={item.name}
          childElements={item.children}
        />
      );
    }

    return (
      <NavLink
        to={item.path}
        key={item.path}
      >
        {({ isActive }) => (
          <Button
            icon={determineIcon(item.type)}
            childrenWrapperClassName={
              !isActive ? styles["menu__button-text"] : ""
            }
            isActive={isActive}
          >
            {item.name}
          </Button>
        )}
      </NavLink>
    );
  });
};

export default DynamicMenu;
