import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import Input from "components/Input/Input";

import styles from "./Pagination.module.scss";

type PaginationProps = {
  totalItems: number;
  itemsPerPage: number;
  currentPage: number;
};

type PaginatorElement = {
  type: "page" | "next" | "prev" | "decorative";
  content: number | string;
};

const maximumItemsPerPage = 200;

const Pagination: FC<PaginationProps> = ({
  totalItems,
  itemsPerPage,
  currentPage,
}) => {
  const [paginator, setPaginator] = useState<PaginatorElement[]>([]);
  const [isInputEmpty, setIsInputEmpty] = useState(false);
  const [inputValue, setInputValue] = useState(itemsPerPage.toString());
  const [searchParams, setSearchParams] = useSearchParams({
    page: currentPage.toString(),
    itemsPerPage: itemsPerPage.toString(),
  });

  const itemsPerPageFromURL = useMemo(() => {
    const itemsPerPageParam = searchParams.get("itemsPerPage");
    let itemsPerPageFromURL = parseInt(
      itemsPerPageParam || itemsPerPage.toString(),
      10
    );

    if (itemsPerPageFromURL > maximumItemsPerPage) {
      itemsPerPageFromURL = maximumItemsPerPage;
    }

    return itemsPerPageFromURL;
  }, [itemsPerPage, searchParams]);

  const totalPages = useMemo(
    () => Math.ceil(totalItems / itemsPerPageFromURL),
    [totalItems, itemsPerPageFromURL]
  );

  const navigate = useNavigate();

  const addQueryParam = useCallback(
    (param: string, value: string) => {
      const searchParams = new URLSearchParams(window.location.search);

      searchParams.set(param, value);

      navigate(`${window.location.pathname}?${searchParams.toString()}`, {
        replace: true,
      });
    },
    [navigate]
  );

  // Update paginator when current page changes
  useEffect(() => {
    const pageParam = searchParams.get("page");
    const itemsPerPageParam = searchParams.get("itemsPerPage") || "20";
    let pageFromURL = parseInt(pageParam || "1", 10);

    // Check that pageFromURL is a valid number and greater than zero
    if (!Number.isInteger(pageFromURL) || pageFromURL <= 0) {
      pageFromURL = 1; // Set default value if parameter is invalid
    }

    if (pageFromURL > totalPages && totalPages > 0) {
      return addQueryParam("page", totalPages.toString());
    }

    const newItemsPerPage =
      Number(itemsPerPageParam) < maximumItemsPerPage
        ? Number(itemsPerPageParam)
        : maximumItemsPerPage;

    const onChangeHandler = (itemsPerPage: string) => {
      setSearchParams((params) => {
        const searchParams = new URLSearchParams(params);
        searchParams.set("itemsPerPage", itemsPerPage.toString());
        return searchParams.toString();
      });
    };

    onChangeHandler(newItemsPerPage.toString());

    const updatePaginator = (currentPage: number) => {
      let pages: PaginatorElement[] = [];

      // Add start, current and end pages
      if (currentPage > 1) {
        pages.push({ type: "prev", content: "‹" });
      }
      if (currentPage >= 1) {
        pages.push({ type: "page", content: 1 });
      }
      if (currentPage > 2) {
        pages.push({ type: "decorative", content: "..." });
      }
      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        if (i > 1 && i < totalPages) {
          pages.push({ type: "page", content: i });
        }
      }
      if (currentPage < totalPages - 1) {
        pages.push({ type: "decorative", content: "..." });
      }
      if (currentPage < totalPages || totalPages > 1) {
        pages.push({ type: "page", content: totalPages });
      }
      if (currentPage < totalPages) {
        pages.push({ type: "next", content: "›" });
      }

      setPaginator(pages);
    };

    updatePaginator(currentPage);
  }, [
    inputValue,
    currentPage,
    itemsPerPage,
    totalPages,
    searchParams,
    addQueryParam,
    setSearchParams,
  ]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      // Prevent handling keydown events when user is typing in input fields
      if (
        event.target instanceof HTMLInputElement ||
        event.target instanceof HTMLTextAreaElement
      ) {
        return;
      }

      let newPageNumber = currentPage;

      switch (event.code) {
        case "ArrowLeft":
          newPageNumber = Math.max(1, currentPage - 1);
          break;
        case "ArrowRight":
          if (currentPage < totalPages) {
            newPageNumber = currentPage + 1;
          }
          break;
        default:
          return;
      }

      addQueryParam("page", newPageNumber.toString());
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [currentPage, totalPages, addQueryParam]);

  const goToPage = useCallback(
    (pageNumber: number | "...") => {
      if (pageNumber !== "...") {
        addQueryParam("page", pageNumber.toString());
      }
    },
    [addQueryParam]
  );

  return (
    <div className={`${styles.pagination}`}>
      <div>
        {paginator.map((page, index) => (
          <button
            key={index}
            className={`${styles["page-item"]}${
              currentPage === page.content
                ? ` ${styles["page-item_active"]}`
                : ""
            }${
              page.type === "prev" || page.type === "next"
                ? ` ${styles["page-regulator"]}`
                : ""
            }`}
            onClick={() => {
              if (page.type === "page") {
                goToPage(Number(page.content));
              } else if (page.type === "next") {
                goToPage(currentPage + 1);
              } else if (page.type === "prev") {
                goToPage(currentPage - 1);
              }
            }}
          >
            {page.content}
          </button>
        ))}
      </div>
      <div className={styles["per-page-controll-container"]}>
        <span className={styles.text}>Show</span>
        <div className={styles["input-container"]}>
          <Input
            value={isInputEmpty ? "" : itemsPerPage}
            type="number"
            min={1}
            onChange={(event) => {
              let value = event.target.value;

              if (value === "") {
                setIsInputEmpty(true);
                return;
              }

              setIsInputEmpty(false);

              if (Number(value) > 0 && !isNaN(Number(value))) {
                setInputValue(value);
                addQueryParam("itemsPerPage", value);
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Pagination;
