import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ModalMap } from "../../typings/modal";

type ModalState = ModalMap;

const initialState: ModalState = {};

export const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    // Use PayloadAction to automatically infer the type of the payload
    openModal: (
      state,
      action: PayloadAction<{ modalFileName: string; meta: any }>
    ) => {
      const { modalFileName, meta } = action.payload;
      state[modalFileName] = { id: modalFileName, meta, open: true };
    },
    closeModal: (state, action: PayloadAction<{ modalFileName: string }>) => {
      const { modalFileName } = action.payload;
      if (state[modalFileName]) {
        state[modalFileName].open = false;
      }
    },
  },
});

export const { openModal, closeModal } = modalSlice.actions;

export default modalSlice.reducer;
