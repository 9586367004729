import { FC } from "react";

const ToggleOnIcon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 3C3.67392 3 2.40215 3.52678 1.46447 4.46447C0.526784 5.40215 0 6.67392 0 8C0 9.32608 0.526784 10.5979 1.46447 11.5355C2.40215 12.4732 3.67392 13 5 13H11C12.3261 13 13.5979 12.4732 14.5355 11.5355C15.4732 10.5979 16 9.32608 16 8C16 6.67392 15.4732 5.40215 14.5355 4.46447C13.5979 3.52678 12.3261 3 11 3H5ZM11 12C12.0609 12 13.0783 11.5786 13.8284 10.8284C14.5786 10.0783 15 9.06087 15 8C15 6.93913 14.5786 5.92172 13.8284 5.17157C13.0783 4.42143 12.0609 4 11 4C9.93913 4 8.92172 4.42143 8.17157 5.17157C7.42143 5.92172 7 6.93913 7 8C7 9.06087 7.42143 10.0783 8.17157 10.8284C8.92172 11.5786 9.93913 12 11 12Z"
        fill="inherit"
      />
    </svg>
  );
};

export default ToggleOnIcon;
