import { SortableField } from "typings/sorting";

export function parseSortingToMongoQuery(
  savedSortingVariants: SortableField[]
) {
  return savedSortingVariants.reduce(
    (acc: { [key: string]: number }, field) => {
      if (field.isSaved) {
        const sortOption = field.value === "asc" ? 1 : -1;
        acc[field.nameDB] = sortOption;
      }

      return acc;
    },
    {}
  );
}
