import React, { FC, ReactElement, useMemo, forwardRef } from "react";
import ContextMenu from "components/ContextMenu/ContextMenu";
import styles from "./Button.module.scss";
import { CheckCircleIcon, ExclamationCircleIcon, GearWideIcon } from "icons";

interface ButtonProps extends React.PropsWithChildren {
  icon?: ReactElement | null;
  withContextMenu?: boolean;
  iconPosition?: string;
  className?: string;
  childrenWrapperClassName?: string;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  isActive?: boolean;
  condensed?: boolean;
  disabled?: boolean;
  squaredIcon?: boolean;
  inverted?: boolean;
  propsForContextMenu?: any;
  withDisabledStyle?: boolean;
  appearance?: "accent" | "positive" | "negative" | "neutral" | "inverted";
  isLoading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      icon = null,
      withContextMenu = false,
      iconPosition = "left",
      className = "",
      childrenWrapperClassName = "",
      onClick = () => {},
      isActive = false,
      condensed = false,
      disabled = false,
      squaredIcon = false,
      inverted = false,
      propsForContextMenu,
      withDisabledStyle = false,
      appearance = "neutral",
      isLoading = false,
    },
    ref
  ) => {
    const buttonClassName = useMemo(() => {
      let currentClassName = styles.button;

      if (condensed) {
        currentClassName += ` ${styles.button_condensed}`;
      }
      if (className) {
        currentClassName += ` ${className}`;
      }
      if (isActive) {
        currentClassName += ` ${styles.button_active}`;
      }
      if (inverted) {
        currentClassName += ` ${styles.button_inverted}`;
      }
      if (withDisabledStyle) {
        currentClassName += ` ${styles.button_disabled}`;
      }
      if (appearance) {
        currentClassName += ` ${styles[`button_${appearance}`]}`;
      }
      if (isLoading) {
        currentClassName += ` ${styles["button_loading"]}`;
      }

      return currentClassName;
    }, [
      condensed,
      className,
      isActive,
      inverted,
      withDisabledStyle,
      appearance,
      isLoading,
    ]);

    const currentIcon = useMemo(() => {
      if (isLoading) {
        return (
          <div className={styles["rotate-animation-wrapper"]}>
            <GearWideIcon />
          </div>
        );
      }

      if (appearance === "positive") {
        return <CheckCircleIcon />;
      }

      if (appearance === "negative") {
        return <ExclamationCircleIcon />;
      }

      return icon;
    }, [isLoading, appearance, icon]);

    return (
      <button
        ref={ref}
        className={buttonClassName}
        onClick={onClick}
        disabled={disabled}
      >
        {icon && iconPosition === "left" && (
          <div
            className={`${styles.icon} ${
              squaredIcon ? styles["icon--squared"] : ""
            }`}
          >
            {currentIcon}
          </div>
        )}
        {children && (
          <span className={`${styles.span} ${childrenWrapperClassName}`}>
            {children}
          </span>
        )}
        {withContextMenu && (
          <div
            className={styles["context-menu"]}
            {...propsForContextMenu}
          >
            <ContextMenu />
          </div>
        )}
        {icon && iconPosition === "right" && icon}
      </button>
    );
  }
);

Button.displayName = "Button";

export default Button;
