import type ICell from "interfaces/ICell";
import type ICellRenderStrategy from "interfaces/ICellRenderStrategy";

export class DefaultCellRenderStrategy implements ICellRenderStrategy<ICell> {
  renderHeaderCell(data: ICell) {
    return <>{data.name}</>;
  }

  renderCell(data: ICell, props?: any): JSX.Element | null {
    const { value, styles } = props;

    return (
      <div className={`${styles["flex-cell"]} ${styles["content-column"]}`}>
        {(value ?? "") !== "" && typeof value !== "object" ? value : "—"}
      </div>
    );
  }
}
