import { SOCKET_URL } from "func/constants";

interface Message {
  type: string;
  payload: any;
}

/**
 * Creates a WebSocket connection and sends a message to the server.
 * @param message - The message to send to the server.
 * @param onMessage - The callback function to handle incoming messages from the server.
 * @returns The WebSocket instance that can be used to close the connection later.
 */
export const createWebsocketConnection = (
  message: Message,
  onMessage: (messageEvent: MessageEvent) => void
) => {
  const protocol = window.location.protocol.includes("https") ? "wss" : "ws";
  const ws = new WebSocket(`${protocol}://${SOCKET_URL}`);

  ws.onopen = () => {
    // Send the text data
    ws.send(JSON.stringify(message));

    // Send the binary data
    if (message.payload.hasOwnProperty("arrayBuffer")) {
      ws.send(message.payload.arrayBuffer);
    }
  };

  ws.onmessage = onMessage;

  return ws;
};
