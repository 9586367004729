import { determineFilterOperator } from "utils/determineFilterOperator";
import type { VisibleField } from "store/slices/fieldVisibilitySlice";
import type { CommonFilter, FilterGroup } from "typings/filter";
import type { SortableField } from "typings/sorting";

interface FilterDataToSave {
  fieldId?: string;
  groupId?: string | null;
  operator?: string;
  value?: string;
  isDraft?: boolean;
  groupFilteringType?: "OR" | "AND";
}

export const prepareViewConfigurationData = ({
  filters,
  sortings,
  fieldVisibilities,
}: {
  filters: CommonFilter[] | FilterGroup[];
  sortings: SortableField[];
  fieldVisibilities: VisibleField[];
}) => {
  const childrenFilters: FilterDataToSave[] = [];
  const filtersToSave = filters
    .map((filter) => {
      const objToSave: FilterDataToSave = {};

      if (filter.isGroup) {
        objToSave.groupId = filter.id;
        objToSave.groupFilteringType = filter.groupFilteringType;

        childrenFilters.push(
          ...filter.children.map((child: any) => {
            return {
              fieldId: child.fieldId,
              groupId: filter.id,
              operator: determineFilterOperator(child.filterOption),
              value: child.value,
              isDraft: !child.isSaved,
            };
          })
        );
      } else {
        // TODO: Fix any type
        objToSave.fieldId = (filter as any).fieldId;
        objToSave.groupId = null;
        objToSave.operator = determineFilterOperator(
          (filter as any).filterOption
        );
        objToSave.value = (filter as any).value;
        objToSave.isDraft = !(filter as any).isSaved;
      }

      return objToSave;
    })
    .concat(childrenFilters);

  return {
    sortings: sortings.map((field) => ({
      fieldId: field.fieldId,
      type: field.value,
      isDraft: !field.isSaved,
    })),
    fieldVisibilities: fieldVisibilities.map((fieldVisibility) => ({
      fieldId: fieldVisibility.fieldId,
      visible: fieldVisibility.isVisible,
      order: fieldVisibility.order,
    })),
    filters: filtersToSave,
  };
};
