import { CommonFilter, FilterGroup } from "typings/filter";

export const stringifyFilters = (filters: CommonFilter[] | FilterGroup[]) => {
  return JSON.stringify(
    filters.map(({ isInEditMode, ...rest }: any) => {
      if (rest.children) {
        rest.children = rest.children.map(
          ({ isInEditMode, ...childRest }: any) => childRest
        );
      }
      return rest;
    })
  );
};
