import { FC } from "react";
import Select, {
  DropdownIndicatorProps,
  GroupBase,
  IndicatorsContainerProps,
  OptionProps,
  StylesConfig,
  components,
} from "react-select";

import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { SelectFilter } from "typings/filter";
import { CheckSquareIcon, ChevronDownIcon } from "icons";

import styles from "./FilterSelect.module.scss";
import { setFilterValue } from "store/slices/filterSlice";
import Button from "components/Button/Button";

const customStyles: StylesConfig<string, true, GroupBase<string>> | undefined =
  {
    control: (provided) => ({
      ...provided,
      minHeight: "28px",
      borderRadius: "10px",
      borderColor: "#FAF2FF",
      ":hover": {
        borderColor: "#FAF2FF",
      },
      cursor: "pointer",
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: "0 6px",
    }),
    input: (provided) => ({
      ...provided,
      margin: "0px",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: "0px 8px",
    }),
    menu: (provided) => ({
      ...provided,
      borderRadius: "10px",
      marginTop: "10px",
      border: "1px solid #F0D8FF",
      boxShadow: "0px 2px 6px 0px #00000014",
      padding: "4px",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "0px",
    }),
    option: (provided) => ({
      ...provided,
      padding: 0,
      backgroundColor: "transparent",
      ":active": {
        backgroundColor: "transparent",
      },
    }),
  };

const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon />
    </components.DropdownIndicator>
  );
};

const IndicatorsContainer = (props: IndicatorsContainerProps<any, true>) => {
  return (
    <components.IndicatorsContainer {...props}>
      <DropdownIndicator {...(props as DropdownIndicatorProps<any, true>)} />
    </components.IndicatorsContainer>
  );
};

type Option = { value: string; label: string };

interface FilterSelectProps {
  value: Option | Option[];
  filter: SelectFilter;
}

const FilterSelect: FC<FilterSelectProps> = ({ value, filter }) => {
  const dispatch = useAppDispatch();

  const options = useAppSelector((state) =>
    state.collection.pageInfo.fields
      .find((field) => field.name === filter.name)
      ?.options.map((option) => ({
        value: option,
        label: option,
      }))
  );

  const handleSelectChange = (selected: any) => {
    dispatch(setFilterValue({ value: selected || [], filter }));
  };

  const _optionsToSet =
    filter.type === "ref" ? (value as any) : (options as any);

  return (
    <Select
      isMulti={filter.isMulti as true | undefined}
      value={filter.value}
      className={styles.select}
      styles={customStyles}
      options={_optionsToSet}
      hideSelectedOptions={false}
      placeholder={`Select options`}
      defaultValue={options?.find(
        (option) => option.value === (value as any).value
      )}
      closeMenuOnSelect={!filter.isMulti}
      onChange={handleSelectChange}
      components={{
        IndicatorSeparator: null,
        IndicatorsContainer,
        DropdownIndicator,
        MultiValue: () => null,
        Option: (props: OptionProps<any, true, GroupBase<string>>) => {
          return (
            <components.Option {...props}>
              <Button
                className={`${styles["option"]}${
                  props.isSelected ? ` ${styles["option--selected"]}` : ""
                }`}
                icon={filter.isMulti ? <CheckSquareIcon /> : null}
              >
                {props.label}
              </Button>
            </components.Option>
          );
        },
      }}
    />
  );
};

export default FilterSelect;
