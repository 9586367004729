import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL, API_VERSION } from "func/constants";
import type { ViewConfiguration } from "pages/Collection/components/ViewConfigurations/interfaces/ViewConfiguration";
import type { APIResponse } from "typings/apiState";

const baseUrl = `${SERVER_URL}/api/${API_VERSION}/main/collections`;

export const apiService = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders(headers) {
      return headers;
    },
    credentials: "include",
  }),
  endpoints: (builder) => ({
    fetchViewConfigurations: builder.query<
      APIResponse<ViewConfiguration[]>,
      string
    >({
      query: (collectionId) => `${collectionId}/view-configurations`,
    }),
    fetchViewConfigurationById: builder.query<
      APIResponse<ViewConfiguration[]>,
      Record<string, string>
    >({
      query: ({ viewConfigurationId, collectionId }) =>
        `${collectionId}/view-configurations/${viewConfigurationId}`,
    }),
    createViewConfiguration: builder.mutation<
      APIResponse<ViewConfiguration>,
      { collectionId: string; body: Record<string, any> | null }
    >({
      query: ({ collectionId, body }) => ({
        url: `${collectionId}/view-configurations`,
        method: "POST",
        body,
      }),
    }),
    updateViewConfiguration: builder.mutation<
      APIResponse<ViewConfiguration>,
      {
        collectionId: string;
        viewConfigurationId: string;
        body: Record<string, any> | null;
      }
    >({
      query: ({ collectionId, viewConfigurationId, body }) => ({
        url: `${collectionId}/view-configurations/${viewConfigurationId}`,
        method: "PUT",
        body,
      }),
    }),
    deleteViewConfiguration: builder.mutation<
      APIResponse<ViewConfiguration>,
      { collectionId: string; viewConfigurationId: string }
    >({
      query: ({ collectionId, viewConfigurationId }) => ({
        url: `${collectionId}/view-configurations/${viewConfigurationId}`,
        method: "DELETE",
      }),
    }),
    updateViewConfigurationsOrder: builder.mutation<
      APIResponse<ViewConfiguration[]>,
      {
        collectionId: string;
        viewConfigurationId: string;
        body: { order: number; viewType: string };
      }
    >({
      query: ({ collectionId, viewConfigurationId, body }) => ({
        url: `${collectionId}/view-configurations/${viewConfigurationId}/change-order`,
        method: "PUT",
        body,
      }),
    }),
  }),
});

export const selectViewConfigurationsResult = (collectionId: string) =>
  apiService.endpoints.fetchViewConfigurations.select(collectionId);

export const selectViewConfigurationByIdResult = (
  params: Record<string, string>
) => apiService.endpoints.fetchViewConfigurationById.select(params);

export const {
  useFetchViewConfigurationsQuery,
  useLazyFetchViewConfigurationsQuery,
  useFetchViewConfigurationByIdQuery,
  useCreateViewConfigurationMutation,
  useUpdateViewConfigurationMutation,
  useDeleteViewConfigurationMutation,
  useUpdateViewConfigurationsOrderMutation,
} = apiService;
