import { FC } from "react";
import Button from "components/Button/Button";

import type { Field } from "typings/filter";

import styles from "./FieldTypePicker.module.scss";

interface FieldTypePickerProps {
  onFieldClick: (field: any) => void;
  icons: Record<string, JSX.Element>;
  fields: any[];
}

const FieldTypePicker: FC<FieldTypePickerProps> = ({
  onFieldClick,
  icons,
  fields,
}) => {
  return fields.map((field) => {
    return (
      <Button
        key={field.nameDB}
        className={styles.field}
        icon={icons[field.type]}
        squaredIcon
        onClick={() => {
          onFieldClick(field);
        }}
      >
        {field.name}
      </Button>
    );
  });
};

export default FieldTypePicker;
