import { FC, useCallback } from "react";
import { clearFilters, setGlobalFilteringType } from "store/slices/filterSlice";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { CloseIcon } from "icons";
import RoundButton from "components/RoundButton/RoundButton";
import ToggleSwitch from "components/ToggleSwitch/ToggleSwitch";

import styles from "./FiltersHeader.module.scss";
import { setActivePanel } from "store/slices/controlPanelSlice";

interface FiltersHeaderProps {
  title: string;
  showCancelButton: boolean;
  showToggleSwitch: boolean;
  onCancel: () => void;
  isFiltersEmpty: boolean;
}

type FilterType = "OR" | "AND";
// Options for the toggle switch
const options = [
  { id: "AND" as FilterType, label: "AND" },
  { id: "OR" as FilterType, label: "OR" },
];

const FiltersHeader: FC<FiltersHeaderProps> = ({
  title,
  showToggleSwitch,
  showCancelButton,
  onCancel,
  isFiltersEmpty,
}) => {
  const dispatch = useAppDispatch();

  const closeFilterBar = useCallback(
    () => dispatch(setActivePanel(null)),
    [dispatch]
  );

  const clearFilterBar = useCallback(
    () => dispatch(clearFilters()),
    [dispatch]
  );

  const {
    body: { globalFilteringType },
  } = useAppSelector((state) => state.collection.filters);

  const handleToggle = useCallback(
    (filterType: FilterType) =>
      dispatch(setGlobalFilteringType({ globalFilteringType: filterType })),
    [dispatch]
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles["left-side"]}>
        <div className={styles.title}>🎚️ {title}</div>
        {showToggleSwitch && (
          <div>
            <ToggleSwitch
              options={options}
              selectedOption={globalFilteringType}
              onToggle={handleToggle}
            />
          </div>
        )}
      </div>
      <div className={styles["right-side"]}>
        {showCancelButton ? (
          <RoundButton
            appearance="neutral"
            size="small"
            style={{ marginRight: "8px" }}
            onClick={onCancel}
          >
            Cancel
          </RoundButton>
        ) : (
          <>
            {!isFiltersEmpty && (
              <RoundButton
                appearance="neutral"
                size="small"
                style={{ marginRight: "15px" }}
                onClick={clearFilterBar}
              >
                Clear
              </RoundButton>
            )}
            <div
              className={styles["close-button"]}
              onClick={closeFilterBar}
            >
              <CloseIcon />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default FiltersHeader;
