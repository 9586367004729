/* eslint-disable react/prop-types */
import React, { useRef, useEffect, useCallback } from "react";
import List from "@editorjs/list";
import LinkTool from "@editorjs/link";
import Header from "@editorjs/header";
import Quote from "@editorjs/quote";
import Embed from "@editorjs/embed";
import InlineCode from "@editorjs/inline-code";
import edjsParser from "editorjs-parser";
import edjsHTML from "editorjs-html";
import EditorJS from "@editorjs/editorjs";

const customParsers = {
  customBlock: function (data, config) {
    return data.text;
  },
  image: function (data, config) {
    return data.text;
  },
  paragraph: function (data, config) {
    return data.text;
  },
  embed: function (data, config) {
    return data.text;
  },
  list: function (data, config) {
    return data.items;
  },
};

const parser = new edjsParser(undefined, customParsers);
const edjsParserHTML = edjsHTML();

const EDITOR_JS_TOOLS = {
  embed: Embed,
  list: {
    class: List,
    inlineToolbar: true,
  },
  linkTool: {
    class: LinkTool,
    inlineToolbar: true,
  },
  header: {
    class: Header,
    config: {
      levels: [2, 3],
      defaultLevel: 3,
    },
    inlineToolbar: true,
  },
  quote: Quote,
  inlineCode: InlineCode,
};

const RichText = ({
  changeData,
  data,
  path,
  dataObjInside,
  rowLength,
  bodyInside,
  dispatch,
}) => {
  const editorJSHolderID = `${bodyInside.nameDB}-editorjs`;
  const textEditor = useRef(null);
  const textEditDiv = useRef(null);
  const latestDataRef = useRef(data);

  // Update ref on data change (because we need to always have the latest data in the onChange function)
  useEffect(() => {
    latestDataRef.current = data;
  }, [data]);

  const onChange = useCallback(async () => {
    const value = await textEditor.current.save();
    const dataUpd = {
      json: value,
      lengthText: parser.parse(value).length,
      text: parser.parse(value),
      html: edjsParserHTML.parse(value),
    };

    await dispatch({
      data: changeData({
        data: latestDataRef.current, // We use the latest data here
        path: [...path, bodyInside.nameDB],
        value: dataUpd,
      }),
    });
  }, [changeData, dispatch, path, bodyInside.nameDB]);

  useEffect(() => {
    if (textEditor.current === null) {
      const editor = new EditorJS({
        holder: editorJSHolderID,
        tools: EDITOR_JS_TOOLS,
        data: dataObjInside[bodyInside.nameDB].json,
        onChange: onChange,
        onReady: () => {},
        placeholder: "Let's write an awesome story!",
      });

      textEditor.current = editor;

      const editorJsContainer = document.getElementById(editorJSHolderID);
      editorJsContainer.addEventListener(
        "focus",
        () => {
          textEditDiv.current.classList.add("active");
        },
        true
      );
      editorJsContainer.addEventListener(
        "blur",
        () => {
          textEditDiv.current.classList.remove("active");
        },
        true
      );

      // TODO: Maybe we need to cleanup the editor
    }
  }, [bodyInside.nameDB, dataObjInside, editorJSHolderID, onChange]);

  return (
    <div
      key={bodyInside.nameDB}
      className="flexim-field-wrapper flexim-field-wrapper--block"
    >
      <h6
        className={`flexim-field-name flexim-field-name--inline${
          bodyInside.requiredField ? " required" : ""
        }`}
      >
        {bodyInside.name}
      </h6>
      <div
        ref={textEditDiv}
        className="text-edit"
      >
        <div
          key={editorJSHolderID}
          id={editorJSHolderID}
        />
      </div>
    </div>
  );
};

export default RichText;
