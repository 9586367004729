import { FC } from "react";

const CloudDownloadIcon: FC = () => {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.406 1.842C5.40548 0.980135 6.68024 0.504139 8 0.5C10.69 0.5 12.923 2.5 13.166 5.079C14.758 5.304 16 6.637 16 8.273C16 10.069 14.502 11.5 12.687 11.5H10C9.86739 11.5 9.74021 11.4473 9.64645 11.3536C9.55268 11.2598 9.5 11.1326 9.5 11C9.5 10.8674 9.55268 10.7402 9.64645 10.6464C9.74021 10.5527 9.86739 10.5 10 10.5H12.688C13.979 10.5 15 9.488 15 8.273C15 7.057 13.98 6.045 12.687 6.045H12.187V5.545C12.188 3.325 10.328 1.5 8 1.5C6.91988 1.50431 5.87684 1.89443 5.059 2.6C4.302 3.252 3.906 4.038 3.906 4.655V5.103L3.461 5.152C2.064 5.305 1 6.452 1 7.818C1 9.285 2.23 10.5 3.781 10.5H6C6.13261 10.5 6.25979 10.5527 6.35355 10.6464C6.44732 10.7402 6.5 10.8674 6.5 11C6.5 11.1326 6.44732 11.2598 6.35355 11.3536C6.25979 11.4473 6.13261 11.5 6 11.5H3.781C1.708 11.5 0 9.866 0 7.818C0 6.055 1.266 4.595 2.942 4.225C3.085 3.362 3.64 2.502 4.406 1.842Z"
        fill="#2E3338"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.6459 16.354C7.69234 16.4006 7.74752 16.4375 7.80827 16.4627C7.86901 16.4879 7.93413 16.5009 7.9999 16.5009C8.06567 16.5009 8.13079 16.4879 8.19153 16.4627C8.25228 16.4375 8.30745 16.4006 8.3539 16.354L11.3539 13.354C11.4478 13.2601 11.5005 13.1328 11.5005 13C11.5005 12.8672 11.4478 12.7399 11.3539 12.646C11.26 12.5521 11.1327 12.4994 10.9999 12.4994C10.8671 12.4994 10.7398 12.5521 10.6459 12.646L8.4999 14.793V6C8.4999 5.86739 8.44722 5.74021 8.35345 5.64645C8.25969 5.55268 8.13251 5.5 7.9999 5.5C7.86729 5.5 7.74011 5.55268 7.64635 5.64645C7.55258 5.74021 7.4999 5.86739 7.4999 6V14.793L5.3539 12.646C5.26001 12.5521 5.13268 12.4994 4.9999 12.4994C4.86712 12.4994 4.73979 12.5521 4.6459 12.646C4.55201 12.7399 4.49927 12.8672 4.49927 13C4.49927 13.1328 4.55201 13.2601 4.6459 13.354L7.6459 16.354Z"
        fill="black"
      />
    </svg>
  );
};

export default CloudDownloadIcon;
