import { FC, useEffect, useRef, useState } from "react";
import { ViewConfiguration } from "./interfaces/ViewConfiguration";

import styles from "./ViewConfigurationButton.module.scss";
import ContextMenu from "components/ContextMenu/ContextMenu";
import useOutsideClick from "hooks/useOutsideClick";
import Button from "components/Button/Button";
import { TrashIcon, PencilIcon } from "icons";
import { Tooltip } from "react-tooltip";

interface ViewConfigurationButtonProps {
  view: ViewConfiguration;
  isActiveViewConfiguration: boolean;
  onViewConfigurationClick: (view: ViewConfiguration) => void;
  onContextMenuEditClick: ({
    position,
    view,
  }: {
    position: DOMRect | null;
    view: ViewConfiguration;
  }) => void;
  onContextMenuDeleteClick: (view: ViewConfiguration) => void;
}

const ViewConfigurationButton: FC<ViewConfigurationButtonProps> = ({
  view,
  isActiveViewConfiguration,
  onViewConfigurationClick,
  onContextMenuEditClick,
  onContextMenuDeleteClick,
}) => {
  const [viewConfigurationButtonPosition, setViewConfigurationButtonPosition] =
    useState<DOMRect | null>(null);
  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [contextMenuPosition, setContextMenuPosition] = useState({
    top: 0,
    left: 0,
  });
  const contextMenuRef = useRef<HTMLDivElement>(null);
  const handleGripClick = (event: React.MouseEvent) => {
    event.stopPropagation();

    setIsContextMenuActive((state: boolean) => !state);

    if (!event.currentTarget.parentElement) return;

    const viewConfigurationPosition =
      event.currentTarget.parentElement?.getBoundingClientRect();

    setViewConfigurationButtonPosition(viewConfigurationPosition);

    if (contextMenuRef.current) {
      setContextMenuPosition({
        top:
          viewConfigurationPosition.top +
          window.scrollY +
          viewConfigurationPosition.height +
          6,
        left:
          viewConfigurationPosition.left +
          window.scrollX +
          viewConfigurationPosition.width -
          contextMenuRef.current.clientWidth,
      });
    }
  };

  useOutsideClick(
    contextMenuRef,
    () => {
      setIsContextMenuActive(false);
    },
    isContextMenuActive
  );

  // To show tooltip only when the name is too long
  const [shouldRenderTooltip, setShouldRenderTooltip] = useState(false);
  const contextMenuRefWidth =
    contextMenuRef.current?.getBoundingClientRect().width || 0;
  useEffect(() => {
    if (contextMenuRef.current) {
      setShouldRenderTooltip(
        contextMenuRef.current && contextMenuRefWidth >= 140
      );
    }
  }, [contextMenuRefWidth]);

  return (
    <div
      ref={contextMenuRef}
      key={view._id}
      className={`${styles["view-type"]}${
        isActiveViewConfiguration ? ` ${styles["view-type--active"]}` : ""
      }`}
      onClick={() => onViewConfigurationClick(view)}
    >
      <div
        className={styles["configuration-name"]}
        data-tooltip-id={`tooltip-${view._id}`}
        data-tooltip-content={view.name}
        data-tooltip-place="right"
      >
        <span>{view.name}</span>
      </div>
      {isActiveViewConfiguration && !view.isDefaultView && (
        <>
          <div
            className={styles["context-menu"]}
            onClick={(event) => {
              handleGripClick(event);
            }}
          >
            <ContextMenu />
          </div>
          <div
            className={styles["context-menu-wrapper"]}
            style={{
              visibility: isContextMenuActive ? "visible" : "hidden",
              opacity: isContextMenuActive ? 1 : 0,
              top: `${contextMenuPosition.top}px`,
              left: `${contextMenuPosition.left}px`,
            }}
          >
            <Button
              className={styles["context-menu-item"]}
              icon={<PencilIcon />}
              onClick={(event) => {
                event.stopPropagation();
                onContextMenuEditClick({
                  position: viewConfigurationButtonPosition,
                  view,
                });
                setIsContextMenuActive(false);
              }}
            >
              Edit Name
            </Button>
            <Button
              className={styles["context-menu-item"]}
              icon={<TrashIcon />}
              onClick={(event) => {
                event.stopPropagation();
                onContextMenuDeleteClick(view);
              }}
            >
              Delete
            </Button>
          </div>
        </>
      )}
      {shouldRenderTooltip && (
        // TODO: Would be good to create general tooltip component
        // because this is a duplicate of the tooltip in the DataTable (same styles)
        <Tooltip
          className={styles.tooltip}
          id={`tooltip-${view._id}`}
        />
      )}
    </div>
  );
};

export default ViewConfigurationButton;
