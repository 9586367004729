/* eslint-disable react/prop-types */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo } from "react";

import EditFieldModal from "./EditFieldModal";
import FieldTypeSelectorModal from "./FieldTypeSelectorModal";
import StartupModal from "./StartupModal";
import RoundButton from "components/RoundButton/RoundButton";

const ModalEdit = ({
  open,
  setOpen,
  dispatch,
  data,
  dataField,
  path,
  setDataField,
  componentsSelect,
  schemasSelect,
  closePopUp,
  lockTypes,
  typeCreate,
  setPreviousData,
  currentStep,
  setCurrentStep,
}) => {
  const i = path[path.length - 1]; // TODO: Rewrite this logic of finding the index of clicked field
  const checkDataField = Object.keys(dataField).length;
  const isNameEmpty = data?.name?.length === 0 || currentStep === 0;

  const [chooseLockTypeNameBD, setChooseLockTypeNameBD] = useState(null);
  const [name, setName] = useState("");

  const [isInitialModalData, setIsInitialModalData] = useState(false); // TODO: That should be fixed. This state is needed because we need to know when to reset the name and slug fields in StartupModal

  const submitText = useMemo(() => {
    // TODO: Fix below logic with buttons
    if (chooseLockTypeNameBD !== null) {
      return "Next";
    }

    if (isNameEmpty || Object.keys(dataField).length === 0) {
      return "Next";
    }

    if (i !== undefined) {
      return "Add";
    }

    return "Add";
  }, [chooseLockTypeNameBD, isNameEmpty, dataField, i]);

  const doesNameDBDuplicateExist = useMemo(() => {
    if (data?.fields) {
      const names = data.fields.map((field) => ({
        id: field._id,
        nameDB: field.nameDB,
      }));

      return names.some(
        (field) =>
          field.nameDB === dataField.nameDB &&
          (field.id !== dataField._id || dataField._id === undefined),
      );
    }
    return false;
  }, [data?.fields, dataField?.nameDB, dataField?._id]);

  const shouldRenderFieldSelector = useMemo(() => {
    if (isNameEmpty) {
      return false;
    }

    if (typeCreate === "model") {
      if (checkDataField === 0) {
        return true;
      }
    }

    if (typeCreate === "page" || typeCreate === "component") {
      if (checkDataField === 0) {
        return true;
      }
    }

    return false;
  }, [typeCreate, isNameEmpty, checkDataField]);

  const shouldRenderFieldEditor = useMemo(() => {
    if (isNameEmpty) {
      return false;
    }

    if (typeCreate === "model") {
      if (checkDataField > 1) {
        return true;
      }
    }

    if (typeCreate === "page" || typeCreate === "component") {
      if (checkDataField !== 0) {
        return true;
      }
    }

    return false;
  }, [typeCreate, isNameEmpty, checkDataField]);

  const isAutomationFieldDisabled = useMemo(() => {
    if (dataField.typeField !== "automat") {
      return false;
    }

    if (dataField.settingField === "") {
      return true;
    }

    if (dataField.settingField === "slugifator") {
      return !dataField.data?.nameDBField;
    }

    const length = dataField.data?.nameDBFields?.filter(Boolean)?.length || 0;

    return length < 2;
  }, [
    dataField.typeField,
    dataField.settingField,
    dataField.data?.nameDBFields,
    dataField.data?.nameDBField,
  ]);

  const isRefFieldDisabled = useMemo(() => {
    if (dataField.typeField === "ref") {
      if (dataField.refTo !== "") {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }, [dataField.typeField, dataField.refTo]);

  return (
    open && (
      <div
        className={`modal fade ${open && "show"}`}
        tabIndex="-1"
      >
        <div className="modal-dialog modal-xl modal-dialog-centered modal-dialog-scrollable">
          <div className="modal-content">
            {isNameEmpty && (
              <StartupModal
                lockTypes={lockTypes}
                chooseLockTypeNameBD={chooseLockTypeNameBD}
                setChooseLockTypeNameBD={setChooseLockTypeNameBD}
                name={name}
                setName={setName}
                setIsInitialModalData={setIsInitialModalData}
                isInitialModalData={isInitialModalData}
                typeCreate={typeCreate}
                setDatabaseName={(nameDB) => {
                  data.nameDB = nameDB;
                  dispatch({ data });
                }}
              />
            )}
            {shouldRenderFieldSelector && (
              <FieldTypeSelectorModal setDataField={setDataField} />
            )}
            {shouldRenderFieldEditor && (
              <EditFieldModal
                dataField={dataField}
                setDataField={setDataField}
                componentsSelect={componentsSelect}
                schemasSelect={schemasSelect}
                data={data}
                doesNameDBDuplicateExist={doesNameDBDuplicateExist}
              />
            )}
            <div className="modal-footer">
              <RoundButton
                appearance="accent"
                data-bs-dismiss="modal"
                onClick={() => {
                  // Reset StartupModal fields to initial state
                  setChooseLockTypeNameBD(null);
                  setIsInitialModalData(true);
                  closePopUp();
                }}
              >
                Cancel
              </RoundButton>
              {isNameEmpty || checkDataField !== 0 ? (
                <RoundButton
                  appearance="inverted"
                  // TODO: Fix it after refactor: disabled logic prevents critical error after clicking this button
                  // while FieldTypeSelectorModal is opened.
                  disabled={
                    (name.length === 0 && dataField?.name?.length === 0) ||
                    (!isNameEmpty && Object.keys(dataField).length === 0) ||
                    (name.length === 0 &&
                      chooseLockTypeNameBD === null &&
                      Object.keys(dataField).length === 0) ||
                    (doesNameDBDuplicateExist && !isNameEmpty) ||
                    // Fix for https://app.clickup.com/t/86bx8vf6q
                    isAutomationFieldDisabled ||
                    isRefFieldDisabled
                  }
                  onClick={() => {
                    // isNameEmpty is true when StartupModal is opened
                    if (isNameEmpty) {
                      // chooseLockTypeNameBD is not null when user selected a lockType in StartupModal
                      if (chooseLockTypeNameBD) {
                        data = { ...lockTypes[chooseLockTypeNameBD] };

                        // Reset StartupModal fields to initial state
                        setChooseLockTypeNameBD(null);
                        setIsInitialModalData(true);

                        // If user selected a lockType and pressed the button we should close the StartupModal
                        setOpen(false);
                        setName("");
                      } else {
                        data.name = name;
                        setCurrentStep(1);
                      }
                    } else {
                      if (i === undefined) {
                        data.fields.push(dataField);
                      } else {
                        data.fields[i] = dataField;
                      }
                      setOpen(false);
                      setName("");
                    }
                    dispatch({ data });
                    setPreviousData(data);
                  }}
                >
                  {submitText}
                </RoundButton>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ModalEdit;
