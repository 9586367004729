import { CommonFilter, FilterGroup } from "typings/filter";

export default function useTotalFilterCount(
  filters: CommonFilter[] | FilterGroup[]
) {
  const countFilters = (filters: CommonFilter[] | FilterGroup[]) => {
    let count = 0;
    filters.forEach((filter) => {
      if (filter.isGroup && filter.children && filter.children.length > 0) {
        count += countFilters(filter.children);
      } else if (!filter.isGroup && (filter as CommonFilter).isSaved) {
        count++;
      }
    });
    return count;
  };

  return countFilters(filters);
}
