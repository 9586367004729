import { FILTERABLE_FIELD_TYPES_AS_CONST } from "constants/index";

type FilterType = (typeof FILTERABLE_FIELD_TYPES_AS_CONST)[number];

export type Option = { label: string; value: string };

export type FilterGroup = {
  id: string;
  isGroup: boolean;
  children: CommonFilter[];
  groupFilteringType: "OR" | "AND";
  isInEditMode: boolean;
};
export interface FilterState {
  body: {
    globalFilteringType: "OR" | "AND";
    savedFilters: CommonFilter[] | FilterGroup[];
  };
  filters: CommonFilter[] | FilterGroup[];
  initialFiltersAsString: string;
}

export interface Field {
  _id: string;
  fieldId: string;
  name: string;
  nameDB: string;
  type: FilterType;
  isMulti: boolean;
  options: [];
  refTo?: string;
  settingField?: string;
}

interface BaseFilter<T = any> {
  parentId: string | null;
  id: string;
  name: string;
  nameDB: string;
  type: FilterType;
  value: string | number | boolean | string[] | T;
  filterOption: string;
  isInEditMode: boolean;
  isSaved: boolean;
  additionalOptions?: any;
  isGroup: boolean;
  children: CommonFilter[];
  groupFilteringType: "OR" | "AND";
}

interface TextFilter extends BaseFilter {
  type: "text";
  value: string;
}

export interface SelectFilter extends BaseFilter {
  type: "select" | "ref";
  value: Option[] | null;
  isMulti: boolean;
}

export interface AutomatFilter extends BaseFilter {
  type: "automat";
  value: string | boolean;
  additionalOptions?: {
    settingField: "slugifator" | "<" | ">" | "=";
  };
}

export interface BooleanFilter extends BaseFilter {
  type: "boolean";
  value: "true" | "false";
}

export interface NumberFilter extends BaseFilter {
  type: "number";
  value: string; // because it comes from the input
}

export function isSelectFilter(filter: BaseFilter): filter is SelectFilter {
  return filter.type === "select" || filter.type === "ref";
}

export function isBooleanFilter(filter: BaseFilter): filter is BooleanFilter {
  return (
    filter.type === "boolean" ||
    (filter.type === "automat" &&
      filter.additionalOptions.settingField !== "slugifator")
  );
}

// Union of all filter types
export type CommonFilter =
  | TextFilter
  | SelectFilter
  | AutomatFilter
  | BooleanFilter
  | BaseFilter
  | NumberFilter; // ... | OtherFilterTypes;
