// A dictionary to map UI filter names to programmatic constants
const filterMappings = {
  Is: "is",
  "Is Not": "is-not",
  Contain: "contain",
  "Doesn't Contain": "not-contain",
  "Is Empty": "is-empty",
  "Is Not Empty": "is-not-empty",
  "=": "number",
  "≠": "number",
  "<": "number",
  ">": "number",
  "≤": "number",
  "≥": "number",
};

export default filterMappings;
