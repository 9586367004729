import { ChangeEvent, forwardRef } from "react";

import styles from "./Input.module.scss";

interface InputProps {
  value?: string | number;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  focus?: boolean;
  type?: string;
  min?: number;
  condensed?: boolean;
  disabled?: boolean;
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      value,
      onChange,
      placeholder,
      focus,
      type = "text",
      min = 1,
      condensed = false,
      disabled = false,
    },
    ref
  ) => {
    return (
      <input
        ref={ref}
        className={`${styles["input"]}${
          condensed ? ` ${styles["input--condensed"]}` : ""
        }`}
        type={type}
        value={value}
        min={min}
        onChange={onChange}
        placeholder={placeholder}
        autoFocus={focus}
        disabled={disabled}
      />
    );
  }
);

Input.displayName = "Input";

export default Input;
