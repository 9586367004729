import { FC } from "react";

const HddOutlineIcon: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.75 10.125H2.25C1.95163 10.125 1.66548 10.2435 1.4545 10.4545C1.24353 10.6655 1.125 10.9516 1.125 11.25V12.375C1.125 12.6734 1.24353 12.9595 1.4545 13.1705C1.66548 13.3815 1.95163 13.5 2.25 13.5H15.75C16.0484 13.5 16.3345 13.3815 16.5455 13.1705C16.7565 12.9595 16.875 12.6734 16.875 12.375V11.25C16.875 10.9516 16.7565 10.6655 16.5455 10.4545C16.3345 10.2435 16.0484 10.125 15.75 10.125ZM2.25 9C1.65326 9 1.08097 9.23705 0.65901 9.65901C0.237053 10.081 0 10.6533 0 11.25L0 12.375C0 12.9717 0.237053 13.544 0.65901 13.966C1.08097 14.3879 1.65326 14.625 2.25 14.625H15.75C16.3467 14.625 16.919 14.3879 17.341 13.966C17.7629 13.544 18 12.9717 18 12.375V11.25C18 10.6533 17.7629 10.081 17.341 9.65901C16.919 9.23705 16.3467 9 15.75 9H2.25Z"
        fill="inherit"
      />
      <path
        d="M5.625 11.8125C5.625 11.9617 5.56574 12.1048 5.46025 12.2102C5.35476 12.3157 5.21168 12.375 5.0625 12.375C4.91332 12.375 4.77024 12.3157 4.66475 12.2102C4.55926 12.1048 4.5 11.9617 4.5 11.8125C4.5 11.6633 4.55926 11.5202 4.66475 11.4148C4.77024 11.3093 4.91332 11.25 5.0625 11.25C5.21168 11.25 5.35476 11.3093 5.46025 11.4148C5.56574 11.5202 5.625 11.6633 5.625 11.8125ZM3.375 11.8125C3.375 11.9617 3.31574 12.1048 3.21025 12.2102C3.10476 12.3157 2.96168 12.375 2.8125 12.375C2.66332 12.375 2.52024 12.3157 2.41475 12.2102C2.30926 12.1048 2.25 11.9617 2.25 11.8125C2.25 11.6633 2.30926 11.5202 2.41475 11.4148C2.52024 11.3093 2.66332 11.25 2.8125 11.25C2.96168 11.25 3.10476 11.3093 3.21025 11.4148C3.31574 11.5202 3.375 11.6633 3.375 11.8125Z"
        fill="inherit"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.60575 4.5C4.50456 4.49972 4.40517 4.52675 4.31805 4.57823C4.23094 4.6297 4.15932 4.70373 4.11075 4.7925L1.332 9.891C1.19652 10.1387 1.12534 10.4164 1.125 10.6988V11.8125H0V10.6988C0 10.2285 0.118125 9.765 0.343125 9.35212L3.12413 4.25475C3.26927 3.98852 3.48346 3.76632 3.74418 3.61151C4.00491 3.4567 4.30253 3.375 4.60575 3.375H13.3942C13.6975 3.375 13.9951 3.4567 14.2558 3.61151C14.5165 3.76632 14.7307 3.98852 14.8759 4.25475L17.6569 9.351C17.8819 9.765 18 10.2285 18 10.6988V11.8125H16.875V10.6988C16.875 10.4165 16.8042 10.1388 16.6691 9.891L13.8881 4.7925C13.8396 4.7039 13.7682 4.62998 13.6813 4.57851C13.5944 4.52705 13.4952 4.49993 13.3942 4.5H4.60575Z"
        fill="inherit"
      />
    </svg>
  );
};

export default HddOutlineIcon;
