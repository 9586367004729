import { FC } from "react";

const ExclamationCircleIcon: FC = () => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_125_15019)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9 16.875C11.0886 16.875 13.0916 16.0453 14.5685 14.5685C16.0453 13.0916 16.875 11.0886 16.875 9C16.875 6.91142 16.0453 4.90838 14.5685 3.43153C13.0916 1.95468 11.0886 1.125 9 1.125C6.91142 1.125 4.90838 1.95468 3.43153 3.43153C1.95468 4.90838 1.125 6.91142 1.125 9C1.125 11.0886 1.95468 13.0916 3.43153 14.5685C4.90838 16.0453 6.91142 16.875 9 16.875ZM9 18C11.3869 18 13.6761 17.0518 15.364 15.364C17.0518 13.6761 18 11.3869 18 9C18 6.61305 17.0518 4.32387 15.364 2.63604C13.6761 0.948212 11.3869 0 9 0C6.61305 0 4.32387 0.948212 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18Z"
          fill="currentColor"
        />
        <path
          d="M7.87744 12.375C7.87744 12.2273 7.90654 12.081 7.96308 11.9445C8.01961 11.808 8.10248 11.684 8.20695 11.5795C8.31141 11.475 8.43543 11.3922 8.57192 11.3356C8.70841 11.2791 8.8547 11.25 9.00244 11.25C9.15018 11.25 9.29647 11.2791 9.43296 11.3356C9.56945 11.3922 9.69347 11.475 9.79794 11.5795C9.9024 11.684 9.98527 11.808 10.0418 11.9445C10.0983 12.081 10.1274 12.2273 10.1274 12.375C10.1274 12.6734 10.0089 12.9595 9.79794 13.1705C9.58696 13.3815 9.30081 13.5 9.00244 13.5C8.70407 13.5 8.41792 13.3815 8.20695 13.1705C7.99597 12.9595 7.87744 12.6734 7.87744 12.375ZM7.98769 5.61937C7.97271 5.47744 7.98774 5.33395 8.03179 5.1982C8.07585 5.06245 8.14795 4.93749 8.24343 4.83141C8.3389 4.72532 8.45561 4.6405 8.58598 4.58244C8.71635 4.52437 8.85747 4.49437 9.00019 4.49437C9.14291 4.49437 9.28403 4.52437 9.4144 4.58244C9.54478 4.6405 9.66148 4.72532 9.75696 4.83141C9.85243 4.93749 9.92453 5.06245 9.96859 5.1982C10.0126 5.33395 10.0277 5.47744 10.0127 5.61937L9.61894 9.56474C9.60571 9.71974 9.53479 9.86412 9.42022 9.96934C9.30564 10.0745 9.15575 10.1329 9.00019 10.1329C8.84464 10.1329 8.69474 10.0745 8.58017 9.96934C8.46559 9.86412 8.39467 9.71974 8.38144 9.56474L7.98769 5.61937Z"
          fill="currentColor"
        />
      </g>
    </svg>
  );
};

export default ExclamationCircleIcon;
