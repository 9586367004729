// "=": "equal",
//     "≠": "not-equal",
//     "<": "less-than",
//     ">": "greater-than",
//     "≤": "less-than-equal",
//     "≥": "greater-than-equal",

type Operator =
  | "="
  | "≠"
  | "<"
  | ">"
  | "≤"
  | "≥"
  | "Is"
  | "Is Not"
  | "Contain"
  | "Doesn't Contain"
  | "Is Empty"
  | "Is Not Empty";

export const determineFilterOperator = (operator: Operator) => {
  switch (operator) {
    case "Is":
      return "is";
    case "Is Not":
      return "is-not";
    case "Contain":
      return "contain";
    case "Doesn't Contain":
      return "not-contain";
    case "Is Empty":
      return "is-empty";
    case "Is Not Empty":
      return "is-not-empty";
    case "=":
      return "is";
    case "≠":
      return "is-not";
    case "<":
      return "lt";
    case ">":
      return "gt";
    case "≤":
      return "lte";
    case "≥":
      return "gte";
    default:
      return "";
  }
};
