import { FC } from "react";

import styles from "./HorizontalDivider.module.scss";

interface HorizontalDividerProps {
  style?: React.CSSProperties;
}

const HorizontalDivider: FC<HorizontalDividerProps> = ({ style }) => {
  return (
    <div
      className={styles.divider}
      style={style}
    />
  );
};

export default HorizontalDivider;
