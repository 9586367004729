import type ICell from "interfaces/ICell";
import type ICellRenderStrategy from "interfaces/ICellRenderStrategy";

export class DateCellRenderStrategy implements ICellRenderStrategy<ICell> {
  renderHeaderCell(data: ICell) {
    return <>{data.name}</>;
  }

  private getDateAndTime(date: Date): string {
    if (isNaN(date.getTime())) return "";
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }

  renderCell(data: ICell, props?: any): JSX.Element | null {
    const { value, styles } = props;

    const dateStr = this.getDateAndTime(new Date(value));

    return (
      <div className={`${styles["flex-cell"]} ${styles["content-column"]}`}>
        {dateStr || "—"}
      </div>
    );
  }
}
