import $ from "jquery";
import { SERVER_URL } from "../func/constants";
import { CONSTANT_MENU } from "./constants";

import { CollectionTypeIcon, SinglePageTypeIcon } from "icons";

const changeData = ({ data, path, value }) => {
  // Work around: delete last element if it is undefined
  if (path[path.length - 1] === undefined) {
    path.pop();
  }

  const lastKey = path.pop();

  const editObj = path.reduce((acc, current) => {
    if (acc[current]) return acc[current];
    else {
      acc[current] = {};
      return acc[current];
    }
  }, data);

  editObj[lastKey] = value;

  return data;
};

const upload = (file, target, callback) => {
  let cmsSlug = null;
  if (window.location.pathname.includes("/front/")) {
    let path = window.location.pathname.split("/front/");
    path.shift();

    if (path.length) {
      path = path[0].split("/")[0];
    }

    cmsSlug = path;
  }
  if (!cmsSlug) return;

  let xhr = new XMLHttpRequest();
  xhr.withCredentials = true;
  const progress = $(target).parent().parent().parent().find(".progress");
  // отслеживаем процесс отправки
  xhr.upload.onprogress = function (event) {
    //   console.log(`Отправлено ${event.loaded} из ${event.total}`);

    progress
      .find(".progress-bar")
      .css("width", `${(event.loaded / event.total) * 100}%`);
  };

  // Ждём завершения: неважно, успешного или нет
  xhr.onloadend = function () {
    if (xhr.status == 200) {
      console.log("Успех");
      callback(JSON.parse(xhr.response));
    } else {
      console.log("Ошибка " + this.status);
    }
  };

  xhr.open("POST", SERVER_URL + "/" + cmsSlug + "/upload-media");
  xhr.send(file);
};

const changeImageData = ({ data, e, type, field, i, updFunk, block }) => {
  if (e.target.files.length) {
    const formData = new FormData();
    formData.append("uploadImg", e.target.files[0]);

    upload(formData, e.target, (result) => {
      console.log(`result`, result);
      if (!block) {
        if (i !== undefined) {
          if (data[type][i][field]) {
            data[type][i][field].link = result.fileName;
          } else {
            data[type][i][field] = {};
            data[type][i][field].link = result.fileName;
          }
        } else {
          if (data[field]) {
            data[type][field].link = result.fileName;
          } else {
            data[type][field] = {};
            data[type][field].link = result.fileName;
          }
        }
      } else {
        if (type) {
          if (data[type].blocks[i][field]) {
            data[type].blocks[i][field].link = result.fileName;
          } else {
            data[type].blocks[i][field] = {};
            data[type].blocks[i][field].link = result.fileName;
          }
        } else {
          if (data.blocks[i].data[field]) {
            data.blocks[i].data[field].link = result.fileName;
          } else {
            data.blocks[i].data[field] = {};
            data.blocks[i].data[field].link = result.fileName;
          }
        }
      }
      updFunk(data);
    });
  }
};

const createDefaultMenu = (result) => {
  const dynamicMenuPages = result[0].map((item) => {
    return {
      name: item.name,
      link: `pages/${item.slug}`,
      icon: <SinglePageTypeIcon />,
    };
  });

  const dynamicMenuCollections = result[1].map((item) => {
    return {
      name: item.name,
      link: `collections/${item.slug}`,
      icon: <CollectionTypeIcon />,
    };
  });

  return [...dynamicMenuCollections, ...dynamicMenuPages, ...CONSTANT_MENU];
};

const copyToBuffer = (text) => {
  const area = document.createElement("textarea");
  document.body.appendChild(area);
  area.value = text;
  area.select();
  document.execCommand("copy");
  document.body.removeChild(area);
};

const errorCatch = (err) => {
  console.error(`err`, err);
};

export {
  changeData,
  changeImageData,
  createDefaultMenu,
  copyToBuffer,
  errorCatch,
};
