import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { fetchCollectionSchema } from "store/thunks/collectionPageInfoThunks";
import { Field } from "typings/filter";

type Sorting = {
  field: string;
  type: string;
};

type Filter = {
  field: string;
  type: string;
  value: string;
};

type FieldVisibility = {
  field: string;
  isVisible: boolean;
};

type ViewConfiguration = {
  _id: string;
  name: string;
  viewTypeId: string;
  fieldVisibilities: FieldVisibility[];
  filters: Filter[];
  sortings: Sorting[];
  order: number;
  __v: number;
};

type CollectionPageInfoState = {
  collectionId: string;
  pageName: string;
  viewConfigurations?: ViewConfiguration[];
  currentViewConfigurationId: ViewConfiguration["_id"] | string;
  currentViewConfiguration?: ViewConfiguration;
  fields: Field[];
  originalFields: Field[];
  items: any[];
  totalItems: number;
  totalPages: number;
};

const initialState: CollectionPageInfoState = {
  collectionId: "",
  currentViewConfigurationId: "",
  pageName: "",
  fields: [],
  originalFields: [],
  items: [],
  totalItems: 0,
  totalPages: 0,
};

const collectionPageInfoSlice = createSlice({
  name: "collectionPageInfo",
  initialState,
  reducers: {
    setCurrentViewConfigurationId(
      state,
      action: PayloadAction<ViewConfiguration["_id"]>
    ) {
      state.currentViewConfigurationId = action.payload;
    },
    setCurrentCollectionPageData(
      state,
      action: PayloadAction<CollectionPageInfoState>
    ) {
      state.items = action.payload.items;
      state.totalItems = action.payload.totalItems;
      state.totalPages = action.payload.totalPages;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchCollectionSchema.fulfilled, (state, action) => {
        const { structuredData, originalFields, name, collectionId } =
          action.payload;

        state.collectionId = collectionId;
        state.pageName = name;
        state.fields = structuredData;
        state.originalFields = originalFields;
      })
      .addCase(fetchCollectionSchema.rejected, (state, action) => {
        console.error("Failed to fetch schema data:", action.error);
      });
  },
});

export const { setCurrentViewConfigurationId, setCurrentCollectionPageData } =
  collectionPageInfoSlice.actions;

export default collectionPageInfoSlice.reducer;
