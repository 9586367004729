import { FC, PropsWithChildren } from "react";

import styles from "./Label.module.scss";

type LabelProps = {
  htmlFor: string;
  label: string;
  style?: React.CSSProperties;
} & PropsWithChildren;

const Label: FC<LabelProps> = ({ children, htmlFor, label, style }) => {
  return (
    <label
      htmlFor={htmlFor}
      className={styles["label"]}
      style={style}
    >
      <p className={styles["text"]}>{label}</p>
      {children}
    </label>
  );
};

export default Label;
