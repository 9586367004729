/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
/**
 * @callback onClickSearch
 * @param {string} searchString
 * @returns {void}
 */
/**
 *
 * @param {{
 * className:string
 * onClick:onClickSearch
 * onChangeTracking:boolean
 * }} param0
 * @returns {JSX.Element}
 */
const SearchElem = ({ className, onClick, onChangeTracking }) => {
  const [search, setSearch] = useState("");
  const [startSearch, setStartSearch] = useState(false);

  return (
    <div className={`input-group align-items-center ${className ?? ""}`}>
      <input
        type="text"
        className="form-control form-floating"
        style={{
          fontSize: "14px",
          borderRadius: "10px",
          marginRight: "4px",
          paddingTop: "4px",
          paddingBottom: "4px",
          paddingLeft: "8px",
        }}
        id="inputGroup"
        aria-describedby="inputGroup"
        placeholder="Search"
        onChange={({ target }) => {
          setSearch(target.value);
          setStartSearch(false);
          if (onChangeTracking) {
            onClick(target.value);
          }
        }}
        value={search}
        onKeyPress={(e) => {
          if (e.key === "Enter") {
            onClick(search);
            setStartSearch(true);
          }
        }}
      />
      <i
        className={`bi ${
          startSearch ? "bi-x-circle" : "bi-search"
        } pointer text-secondary`}
        style={{
          display: "flex",
          width: 34,
          height: 34,
          alignItems: "center",
          justifyContent: "center",
        }}
        onClick={() => {
          if (startSearch) {
            setSearch("");
            onClick("");
            setStartSearch(false);
          } else {
            onClick(search);
            setStartSearch(true);
          }
        }}
      />
    </div>
  );
};

export default SearchElem;
