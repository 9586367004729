import { RootState } from "store/store";

export const selectSortableFields = (state: RootState) => {
  return state.collection.sorting.sortableFields;
};

export const getSavedSortableFields = (state: RootState) => {
  return state.collection.sorting.savedSortableFields;
};

export const selectHaveChangesInSorting = (state: RootState) => {
  return (
    state.collection.sorting.initialSortableFieldsStateAsJson !==
    JSON.stringify(state.collection.sorting.savedSortableFields)
  );
};
