import Button from "components/Button/Button";
import EyeFilledIcon from "icons/EyeFilledIcon";
import EyeSlashIcon from "icons/EyeSlashIcon";
import { FC, useCallback } from "react";
import { Draggable } from "react-beautiful-dnd";
import iconsByType from "utils/iconsByType";
import {
  toggleVisibility,
  type VisibleField,
} from "store/slices/fieldVisibilitySlice";
import { useAppDispatch } from "hooks/useAppDispatch";

import styles from "./FieldVisibility.module.scss";

interface FieldItemProps {
  field: VisibleField;
  index: number;
  isDragDisabled?: boolean;
  isVisibilityToggleDisabled?: boolean;
}

const FieldItem: FC<FieldItemProps> = ({
  field,
  index,
  isDragDisabled,
  isVisibilityToggleDisabled,
}) => {
  const dispatch = useAppDispatch();

  const toggleVisibilityHandler = useCallback(
    (id: string) => {
      dispatch(toggleVisibility({ id }));
    },
    [dispatch]
  );

  const draggableWrapperClassName = `${styles["draggable-wrapper"]}${
    isVisibilityToggleDisabled ? ` ${styles["draggable-wrapper_disabled"]}` : ""
  }`;

  return (
    <Draggable
      key={field.id}
      draggableId={field.id}
      index={index}
      isDragDisabled={isDragDisabled}
    >
      {(provided) => {
        return (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={draggableWrapperClassName}
          >
            <Button
              icon={field.isVisible ? <EyeFilledIcon /> : <EyeSlashIcon />}
              withContextMenu={!isDragDisabled}
              onClick={() => {
                if (isVisibilityToggleDisabled) {
                  return;
                }

                toggleVisibilityHandler(field.id);
              }}
              propsForContextMenu={{
                ...provided.dragHandleProps,
              }}
              withDisabledStyle={isVisibilityToggleDisabled}
            >
              <div className={styles["button-inner-content"]}>
                <div className={styles.icon}>{iconsByType[field.type]}</div>
                {field.name}
              </div>
            </Button>
          </div>
        );
      }}
    </Draggable>
  );
};

export default FieldItem;
