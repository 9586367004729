import * as handlers from "./filterHandlers";

export const filterHandlers = {
  is: handlers.handleIsFilter,
  "is-not": handlers.handleIsNotFilter,
  contain: handlers.handleContainFilter,
  "not-contain": handlers.handleNotContainFilter,
  "is-empty": handlers.handleIsEmptyFilter,
  "is-not-empty": handlers.handleIsNotEmptyFilter,
  boolean: handlers.handleBooleanFilter,
  number: handlers.handleNumberFilter,
  automat: handlers.handleAutomatFilter,
};
