import { FC, useCallback } from "react";
import { useAppDispatch } from "hooks/useAppDispatch";
import { CloseIcon } from "icons";
import { setActivePanel } from "store/slices/controlPanelSlice";

import styles from "./TableControlHeader.module.scss";
import CloseButton from "components/CloseButton/CloseButton";
import RoundButton from "components/RoundButton/RoundButton";

interface TableControlHeaderPropsBase {
  title: string;
  onClose?: () => void;
}

interface TableControlHeaderPropsWithClearButton
  extends TableControlHeaderPropsBase {
  withClearButton: true;
  onClear: () => void;
  showCancelButton: boolean;
  onCancel: () => void;
}

interface TableControlHeaderPropsWithoutClearButton
  extends TableControlHeaderPropsBase {
  withClearButton?: false;
  onClear?: never;
  showCancelButton?: false;
  onCancel?: never;
}

type TableControlHeaderProps =
  | TableControlHeaderPropsWithClearButton
  | TableControlHeaderPropsWithoutClearButton;

const TableControlHeader: FC<TableControlHeaderProps> = ({
  title,
  withClearButton,
  onClear,
  showCancelButton = false,
  onCancel = () => {},
  onClose = () => {},
}) => {
  const dispatch = useAppDispatch();

  return (
    <div className={styles.wrapper}>
      <span className={styles.title}>{title}</span>
      <div className={styles.tools}>
        {withClearButton && !showCancelButton && (
          <RoundButton
            appearance="neutral"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={onClear}
          >
            Clear
          </RoundButton>
        )}
        {showCancelButton && (
          <RoundButton
            appearance="neutral"
            size="small"
            style={{ marginRight: "15px" }}
            onClick={onCancel}
          >
            Cancel
          </RoundButton>
        )}
        <CloseButton onClose={onClose} />
      </div>
    </div>
  );
};

export default TableControlHeader;
