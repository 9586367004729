/* eslint-disable react/prop-types */
import { useEffect, useReducer, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";

import api from "../../func/api";
import { settingsFields } from "../../func/constants";
import ModalOvelay from "../common/ModalOverlay";
import SearchElem from "../common/SearchElem";
import ModalEdit from "./ModalEdit";

import { useModal } from "../../components/modal/useModal";
import RoundButton from "components/RoundButton/RoundButton";
import Button from "components/Button/Button";

import { HddOutlineIcon, HourglassIcon, PlusIconWithoutRect } from "icons";

import { ReactComponent as RocketSVG } from "img/rocket.svg";

const activeTypeToText = {
  collections: ["Collection", "collection"],
  pages: ["Page", "page"],
  components: ["Component", "component"],
};

const changeState = (state, action) => {
  return { ...action.data };
};

const defaultData = {
  name: "",
  fields: [],
};

const createNew = ({ type, data }) => {
  if (type === "model") {
    return api.postConstr(data);
  } else if (type === "component") {
    return api.postComponents(data);
  } else {
    return api.postSinglePage(data);
  }
};
const deletePoint = ({ type, id }) => {
  if (type === "model") {
    return api.deleteSchema(id);
  } else if (type === "component") {
    return api.deleteComponent(id);
  } else {
    return api.deleteSinglePage(id);
  }
};

const Constructor = ({ slug, type, cms, updateDynamicMenu }) => {
  const { onOpen: openDeleteModal, onClose: closeDeleteModal } =
    useModal("DeleteModal");

  const navigate = useNavigate();

  const [data, dispatch] = useReducer(changeState, defaultData);
  const [initialData, setInitialData] = useState(null);
  const [previousData, setPreviousData] = useState(null);
  const [activeType, setActiveType] = useState("");
  const [schemas, setSchemas] = useState([]);
  const [components, setComponents] = useState([]);
  const [singlePage, setSinglePage] = useState([]);
  const [dataField, setDataField] = useState({});
  const [currentStep, setCurrentStep] = useState(0);

  const [open, setOpen] = useState(false);
  const [modalPath, setModalPath] = useState([]);
  const [typeCreate, setTypeCreate] = useState("model");
  const [search, setSearch] = useState("");

  const [lockTypes, setLockTypes] = useState({});

  const closePopUp = () => {
    setOpen(false);
    setDataField({});
  };

  const schemasSelect = [];
  const schemasList = [];
  const componentsSelect = [];
  const componentsList = [];
  const singlePagesList = [];

  useEffect(() => {
    api
      .getLockTypes()
      .then((result) => {
        setLockTypes(result);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  const getDatas = useCallback(async () => {
    try {
      const [schemasResult, componentsResult, singlePagesResult] =
        await Promise.all([
          api.getConstr(),
          api.getComponents(),
          api.getSinglePages(),
        ]);

      const setIfAvailable = (result, setState) => {
        if (result) {
          setState(result);
        }
      };

      setIfAvailable(schemasResult, setSchemas);
      setIfAvailable(componentsResult, setComponents);
      setIfAvailable(singlePagesResult, setSinglePage);

      if (!slug) {
        const navigationOptions = [
          {
            result: schemasResult,
            path: `/front/main/constructor/${schemasResult[0]?.slug}`,
          },
          {
            result: singlePagesResult,
            path: `/front/main/constructor/sp_${singlePagesResult[0]?.slug}`,
          },
          {
            result: componentsResult,
            path: `/front/main/constructor/component_${componentsResult[0]?.slug}`,
          },
        ];

        const targetOption = navigationOptions.find(
          (option) => option.result && option.result.length > 0,
        );
        if (targetOption) {
          navigate(targetOption.path);
        }
      }
    } catch (err) {
      console.error(err);
    }
  }, [navigate, slug]);

  useEffect(() => {
    getDatas();
  }, [getDatas]);

  useEffect(() => {
    const findActiveData = () => {
      if (!slug) return null;

      const dataTypes = [
        { data: schemas, type: "collections", create: "model", slugPrefix: "" },
        { data: singlePage, type: "pages", create: "page", slugPrefix: "sp_" },
        {
          data: components,
          type: "components",
          create: "component",
          slugPrefix: "component_",
        },
      ];

      for (const { data, type, create, slugPrefix } of dataTypes) {
        const activeData = data.find(
          (model) => `${slugPrefix}${model.slug}` === slug,
        );
        if (activeData) {
          return { activeData, activeType: type, typeCreate: create };
        }
      }

      return {
        activeData: null,
        activeType: "collections",
        typeCreate: "model",
      };
    };

    const { activeData, activeType, typeCreate } = findActiveData() || {};

    setInitialData(activeData);
    setActiveType(activeType);
    setTypeCreate(typeCreate);

    if (activeData) {
      dispatch({ data: activeData ?? defaultData });
    }
  }, [slug, schemas, singlePage, components]);

  useEffect(() => {
    setPreviousData(initialData);
  }, [slug, initialData]);

  const fields = [];

  // Rendering items for Collection schema
  schemas.forEach((el, i) => {
    if (!search || el.name.toLowerCase().includes(search.toLowerCase())) {
      schemasSelect.push({ label: el.name, value: el.nameDB });
      const isActive = data.slug === el.slug && activeType === "collections";
      schemasList.push(
        <Link
          key={`schemas-${i}`}
          className={`constr-item d-block ${isActive ? "active" : ""}`}
          to={`/front/${cms}/constructor/${el.slug}`}
        >
          <p className="ps-0 m-0" type="button">
            {el.name}
          </p>
        </Link>,
      );
    }
  });

  // Rendering items for Single Type schema
  singlePage.forEach((el, i) => {
    if (!search || el.name.toLowerCase().includes(search.toLowerCase())) {
      const pageSlug = `sp_${el.slug}`;
      const isActive = data.slug === el.slug && activeType === "pages";
      singlePagesList.push(
        <Link
          key={`single-${i}`}
          className={`constr-item d-block ${isActive ? "active" : ""}`}
          to={`/front/${cms}/constructor/${pageSlug}`}
        >
          <p className="ps-0 m-0" type="button">
            {el.name}
          </p>
        </Link>,
      );
    }
  });

  // Rendering items for Components schema
  components.forEach((el, i) => {
    if (!search || el.name.toLowerCase().includes(search.toLowerCase())) {
      const isActive = data.slug === el.slug && activeType === "components";

      componentsList.push(
        <Link
          key={`components-${i}`}
          className={`constr-item d-block ${isActive ? "active" : ""}`}
          to={`/front/${cms}/constructor/component_${el.slug}`}
        >
          <p className="ps-0 m-0" type="button">
            {el.name}
          </p>
        </Link>,
      );
    }

    const isNewContentType = data._id === undefined;
    const slugWithoutPrefix = slug?.replace("component_", "");

    const doesCurrentPageSlugComponentExistInFields =
      !isNewContentType &&
      el.fields.some((field) => {
        return (
          field?.component?.nameDB?.toLowerCase() ===
          slugWithoutPrefix?.toLowerCase()
        );
      }) &&
      typeCreate === "component";

    // If el.slug (el.nameDB) is not equal to the current pageSlug (to prevent component from being added to itself)
    if (el.nameDB !== slugWithoutPrefix || isNewContentType) {
      componentsSelect.push({
        label: el.name,
        value: el,
        isDisabled: doesCurrentPageSlugComponentExistInFields, // the current component is in the fields of the element of iteration
        disabledDescription: doesCurrentPageSlugComponentExistInFields
          ? `The ${data.name} component already exists in the ${el.name} component`
          : "",
      });
    }
  });

  let pathTimeCont = ["body"];
  let isComponent = false;

  const lockTypeData =
    data && data.lockName && lockTypes && lockTypes[data.lockName]
      ? lockTypes[data.lockName]
      : null;

  const lockTypeNameFields = lockTypeData
    ? lockTypeData.fields.map((el) => el.nameDB)
    : [];

  const isEmptyConstructor =
    schemasList.length === 0 &&
    singlePagesList.length === 0 &&
    componentsList.length === 0 &&
    !data.name;

  // TODO: Hard to maintain. Need to refactor
  const createHTMLFields = (prev, el, i, arr, isInitialIteration = true) => {
    const shouldBeLocked = (() => {
      if (lockTypeData !== null && lockTypeNameFields.includes(el.nameDB)) {
        return true;
      }

      if (
        el.nameDB === "name" &&
        activeType === "collections" &&
        !isComponent
      ) {
        return true;
      }

      return false;
    })();

    const isDeletable = (() => {
      if (lockTypeData === null || !lockTypeNameFields.includes(el.nameDB)) {
        if (el.nameDB === "name" && activeType === "collections") {
          return false;
        }

        if (isComponent) {
          return false;
        }

        return true;
      }

      return false;
    })();

    const onClick = () => {
      setCurrentStep(3);
      if (el.nameDB === "name" && activeType === "collections") {
        return;
      }

      setOpen(true);
      setModalPath([...pathTimeCont, i]);
      setDataField(el);
    };

    const onDeleteClick = (e) => {
      e.stopPropagation();
      openDeleteModal({
        show: true,
        title: "Delete field?",
        onClose: closeDeleteModal,
        onDelete: async () => {
          data.fields.splice(i, 1);
          dispatch({ data });

          closeDeleteModal();
        },
        innerContent: `Field «${el.name}» and all its data will be deleted forever.`,
      });
    };

    if (el.typeField !== "component") {
      prev.push(
        <div
          key={el._id || `${el.typeField}-${el.name}`}
          className={`d-flex p-2 flex-row${!isComponent ? " pointer" : ""}`}
          onClick={onClick}
        >
          <div style={{ position: "relative", boxSizing: "content-box" }}>
            {settingsFields[el.typeField]?.badge}
          </div>

          <span className="pe-1">{el.name}</span>
          <span className="text-secondary">
            ({settingsFields[el.typeField]?.name})
          </span>
          {shouldBeLocked && (
            <i
              className="bi bi-lock ms-auto"
              style={{
                color: "#C2C2C2",
                fontSize: "18px",
              }}
            ></i>
          )}
          {isDeletable && (
            <i
              className="bi bi-trash pointer ms-auto page-construct__trash-icon"
              onClick={onDeleteClick}
            />
          )}
        </div>,
      );
    } else {
      isComponent = true;
      prev.push(
        <div
          key={el._id || `${el.typeField}-${el.name}`}
          className="comp-block-list pointer"
          onClick={onClick}
        >
          <div className="d-flex p-2 flex-row">
            {settingsFields[el.typeField].badge}
            <span className="pe-1">{el.name}</span>
            <span className="text-secondary">
              ({settingsFields[el.typeField].name})
            </span>
            {isInitialIteration && (
              <i
                className="bi bi-trash pointer ms-auto page-construct__trash-icon"
                onClick={onDeleteClick}
              />
            )}
          </div>
          <div className="ms-21 ps-3 comp-block-list__elems">
            {el.component
              ? el.component?.fields?.reduce((prev, current, i, arr) => {
                  return createHTMLFields(prev, current, i, arr, false);
                }, [])
              : null}
          </div>
        </div>,
      );
      isComponent = false;
      pathTimeCont = ["body"];
    }

    return prev;
  };

  if (data.fields) fields.push(data.fields.reduce(createHTMLFields, []));

  const [isSaving, setIsSaving] = useState(false);
  const [isError, setIsError] = useState(false);

  // TODO: Decouple edit to another component (components)
  const edit = (
    <div className="container-fluid">
      <div className="row">
        <div className="col block-2 page-construct__list">
          <div
            style={{
              padding: "0 10px",
              marginBottom: "20px",
            }}
          >
            <SearchElem onClick={(searchString) => setSearch(searchString)} />
          </div>
          <div className="page-construct__element-wrapper mb-3">
            <h6
              className="d-flex justify-content-between align-items-center"
              style={{
                marginLeft: "8px",
                marginBottom: "0",
              }}
            >
              Collections ({schemasList.length}){" "}
              <i
                className="bi bi-plus-circle curs-poin color-violet"
                onClick={() => {
                  setCurrentStep(0);
                  setOpen(true);
                  if (typeCreate !== "model") setTypeCreate("model");
                  setActiveType("collections");

                  setPreviousData(data);
                  dispatch({
                    data: {
                      ...defaultData,
                      fields: [
                        {
                          name: "Name",
                          nameDB: "name",
                          typeField: "input",
                          settingField: "input",
                          defaultValue: "",
                          regexpField: "",
                          requiredField: false,
                          uniqueField: false,
                          closeField: false,
                          maxLengthField: 0,
                          minLengthField: 0,
                        },
                      ],
                    },
                  });
                }}
              ></i>
            </h6>
            {schemasList.length > 0 && (
              <div style={{ marginTop: "10px" }}>{schemasList.slice(0, 3)}</div>
            )}
            {schemasList.length > 3 && (
              <>
                <div
                  className="collapse bg-transparent border-0 w-100 "
                  id="collapseNewType"
                >
                  {schemasList.slice(3, schemasList.length)}
                </div>
                <a
                  className="d-block color-violet constr-item watch-all"
                  data-bs-toggle="collapse"
                  href="#collapseNewType"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseNewType"
                  type="button"
                  style={{
                    opacity: 0.5,
                  }}
                >
                  Watch all
                </a>
              </>
            )}
          </div>
          <div className="page-construct__element-wrapper mb-3">
            <h6
              className="d-flex justify-content-between align-items-center"
              style={{
                marginLeft: "8px",
                marginBottom: "0",
              }}
            >
              Single Pages ({singlePagesList.length})
              <i
                className="bi bi-plus-circle curs-poin color-violet"
                onClick={() => {
                  setCurrentStep(0);
                  setOpen(true);
                  setActiveType("pages");
                  if (typeCreate !== "page") setTypeCreate("page");
                  setPreviousData(data);

                  // TODO: defaultData here is modified for unknown reason. Need to refactor
                  dispatch({
                    data: { ...defaultData, fields: [] },
                  });
                }}
              ></i>
            </h6>
            {singlePagesList.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                {singlePagesList.slice(0, 3)}
              </div>
            )}
            {singlePagesList.length > 3 && (
              <>
                <div
                  className="collapse bg-transparent border-0 w-100 "
                  id="collapseOneType"
                >
                  {singlePagesList.slice(3, singlePagesList.length)}
                </div>
                <a
                  className="d-block color-violet constr-item watch-all"
                  data-bs-toggle="collapse"
                  href="#collapseOneType"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseOneType"
                  type="button"
                  style={{
                    opacity: 0.5,
                  }}
                >
                  Watch all
                </a>
              </>
            )}
          </div>
          <div className="page-construct__element-wrapper mb-3">
            <h6
              className="d-flex justify-content-between align-items-center"
              style={{
                marginLeft: "8px",
                marginBottom: "0",
              }}
            >
              Components ({componentsList.length})
              <i
                className="bi bi-plus-circle curs-poin color-violet"
                onClick={() => {
                  setCurrentStep(0);
                  setOpen(true);
                  if (typeCreate !== "component") setTypeCreate("component");
                  setPreviousData(data);
                  setActiveType("components");
                  dispatch({
                    data: { ...defaultData, fields: [] },
                  });
                }}
              ></i>
            </h6>
            {componentsList.length > 0 && (
              <div style={{ marginTop: "10px" }}>
                {componentsList.slice(0, 3)}
              </div>
            )}

            {componentsList.length > 3 && (
              <>
                <div
                  className="collapse bg-transparent border-0 w-100 "
                  id="collapseComp"
                >
                  {componentsList.slice(3, componentsList.length)}
                </div>
                <a
                  className="d-block color-violet constr-item watch-all"
                  data-bs-toggle="collapse"
                  href="#collapseComp"
                  role="button"
                  aria-expanded="false"
                  aria-controls="collapseComp"
                  type="button"
                  style={{
                    opacity: 0.5,
                  }}
                >
                  Watch all
                </a>
              </>
            )}
          </div>
        </div>
        {!isEmptyConstructor && (
          <div className="col page-construct__fields">
            <div
              style={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <div
                style={{
                  minWidth: "500px",
                  maxWidth: "1000px",
                  padding: "0 10%",
                  width: "100%",
                }}
              >
                <div className="d-flex justify-content-between align-items-center mb-4 mt-4">
                  <div>
                    <h5 className="m-0">{data.name}</h5>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      gap: "14px",
                    }}
                  >
                    <RoundButton onClick={getDatas}>
                      Discard changes
                    </RoundButton>
                    <RoundButton
                      before={isSaving ? <HourglassIcon /> : <HddOutlineIcon />}
                      appearance={`${
                        isSaving
                          ? "positive"
                          : isError
                            ? "negative-high-contrast"
                            : "inverted"
                      }`}
                      onClick={(e) => {
                        setIsSaving(true);
                        createNew({ type: typeCreate, data, api })
                          .then(() => {
                            getDatas();
                            setDataField({});
                          })
                          .catch((err) => {
                            setIsSaving(false);
                            setIsError(true);
                            const timer = setTimeout(() => {
                              setIsError(false);
                              clearTimeout(timer);
                            }, 2000);
                          })
                          .finally(() => {
                            getDatas();
                            updateDynamicMenu();
                            const navigateTo = () => {
                              if (typeCreate === "page") {
                                navigate(`sp_${data.nameDB}`);
                              } else if (typeCreate === "component") {
                                navigate(`component_${data.nameDB}`);
                              } else {
                                navigate(`${data.nameDB}`);
                              }
                            };

                            navigateTo();
                            const timer = setTimeout(() => {
                              setIsSaving(false);
                              clearTimeout(timer);
                            }, 2000);
                          });
                      }}
                    >
                      Save
                    </RoundButton>
                  </div>
                </div>
                <div className="d-flex justify-content-between align-items-center">
                  <p
                    className="mb-0"
                    style={{
                      fontSize: "14px",
                    }}
                  >
                    Fields:{" "}
                    {data && data.fields
                      ? data.fields.length
                      : "Data not available"}
                  </p>

                  <div>
                    <Button
                      icon={<PlusIconWithoutRect />}
                      appearance="accent"
                      onClick={() => {
                        setCurrentStep(1);
                        setDataField({});
                        setOpen(true);
                        setModalPath([]);
                      }}
                    >
                      Add field
                    </Button>
                  </div>
                </div>
                <div className="product-card fs-6">{fields}</div>
                <div className="d-flex justify-content-end align-items-center mb-4">
                  <RoundButton
                    appearance="negative"
                    disabled={data.name === ""}
                    onClick={() => {
                      openDeleteModal({
                        show: true,
                        title: `Delete ${activeTypeToText[activeType][1]}?`,
                        onClose: closeDeleteModal,
                        onDelete: async () => {
                          await deletePoint({
                            type: typeCreate,
                            id: data._id,
                            api,
                          });

                          closeDeleteModal();
                          dispatch({ data: defaultData });
                          navigate(`/front/main/constructor`);
                        },
                        innerContent: `${activeTypeToText[activeType][0]} "${data.name}" and all its data will be deleted forever.`,
                      });
                    }}
                  >
                    Delete {data.name}
                  </RoundButton>
                </div>
              </div>
            </div>
          </div>
        )}
        {isEmptyConstructor && (
          <div
            className="col"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flexDirection: "column",
            }}
          >
            <RocketSVG />
            <span
              style={{
                marginTop: 20,
                textAlign: "center",
                fontWeight: 500,
                color: "#2E3338",
              }}
            >
              It’s feel lonely here. <br />
              Create collection, single page or component
            </span>
          </div>
        )}
      </div>
    </div>
  );

  return (
    <main
      style={{
        fontFamily: "Roboto",
      }}
    >
      {edit}
      <ModalOvelay
        open={open}
        closePopUp={() => {
          // TODO: This hack with setModal Path({}) is necessary due to this task https://app.clickup.com/t/860rvc2a2
          // Need to refactor
          setCurrentStep(1);
          setModalPath([]);
          closePopUp();
          dispatch({ data: previousData });
        }}
      >
        <ModalEdit
          currentStep={currentStep}
          setCurrentStep={setCurrentStep}
          data={data}
          open={open}
          setOpen={setOpen}
          dispatch={dispatch}
          path={modalPath}
          dataField={dataField}
          setDataField={setDataField}
          componentsSelect={componentsSelect}
          schemasSelect={schemasSelect}
          closePopUp={() => {
            // TODO: This hack with setModal Path({}) is necessary due to this task https://app.clickup.com/t/860rvc2a2
            // Need to refactor
            setCurrentStep(1);
            setModalPath([]);
            closePopUp();
            dispatch({ data: previousData });
          }}
          lockTypes={lockTypes}
          typeCreate={typeCreate}
          setPreviousData={setPreviousData}
        />
      </ModalOvelay>
    </main>
  );
};

export default Constructor;
