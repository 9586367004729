import { FC } from "react";

const TextIcon: FC = () => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1.9635 11.4459L2.78863 8.99325H5.8275L6.6535 11.4459H7.7525L4.8475 3.28125H3.78175L0.875 11.4459H1.9635ZM4.326 4.51325L5.5475 8.14975H3.07125L4.29625 4.51325H4.326ZM12.3288 10.6619H12.3594V11.4459H13.3464V7.10938C13.3464 5.78813 12.3716 5.0575 11.0311 5.0575C9.51213 5.0575 8.76487 5.859 8.69837 6.95975H9.66788C9.72738 6.3315 10.1885 5.9185 10.9952 5.9185C11.8449 5.9185 12.3235 6.3735 12.3235 7.1995V7.83912H10.6662C9.22512 7.84525 8.4595 8.53912 8.4595 9.63988C8.4595 10.794 9.29688 11.5474 10.5114 11.5474C11.4389 11.5474 12.0129 11.1711 12.3296 10.6627L12.3288 10.6619ZM10.7861 10.6925C10.1281 10.6925 9.51213 10.3451 9.51213 9.604C9.51213 9.03525 9.88313 8.62838 10.7441 8.62838H12.3235V9.35812C12.3235 10.1421 11.6655 10.6925 10.7861 10.6925Z"
        fill="inherit"
      />
    </svg>
  );
};

export default TextIcon;
