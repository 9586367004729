/* eslint-disable react/prop-types */
import React, { useReducer, useState, useEffect } from "react";
import FilterTable from "../common/FilterTable";
import { fieldsSetting, parseFilterToQuery } from "../common/funcs/common";
import ModalOvelay from "../common/ModalOverlay";
import SidebarBox from "../common/SidebarBox";

const InfoCMSFilterController = ({
  setFilters,
  schema,
  nameColl,
  filter,
  filterOn,
  setFilterOn,
}) => {
  const [typeAnd, setTypeAnd] = useState(true);
  const [filtersArr, setFiltersArr] = useState([]);

  useEffect(() => {
    // if (filters.$or) {
    //   setTypeAnd(false);
    //   setFiltersArr(filters.$or);
    // } else if (filters.$and) {
    //   setTypeAnd(true);
    //   setFiltersArr(filters.$and);
    // } else {
    //   setFiltersArr(filters);
    // }
    if (filter.filterData) {
      setFiltersArr(filter.filterData);
    } else {
      setFiltersArr(filter);
    }
  }, [filter]);

  const setData = (filters) => {
    setFilters(filters, typeAnd);
    setFilterOn(false);
  };

  console.log("filters :>> ", filter);

  return (
    <ModalOvelay
      key={"filterSettings"}
      open={filterOn}
      timeout={300}
      closePopUp={(e) => {
        if (e) {
          e.stopPropagation();
          e.preventDefault();
        }
        setFilterOn(false);
      }}
    >
      <SidebarBox open={filterOn}>
        <FilterTable
          schema={schema}
          nameColl={nameColl}
          filters={filtersArr}
          setFilters={setFiltersArr}
          typeAnd={typeAnd}
          setTypeAnd={setTypeAnd}
          search={""}
          pageNumber={0}
          setFilterOn={setFilterOn}
          inInfoCMS={true}
          setData={setData}
          fields={schema.reduce((value, field) => {
            value[field.name] = field.typeField;
            return value;
          }, {})}
          selectOps={schema.map((el) => ({
            label: el.name,
            value: el.nameDB,
            type: fieldsSetting[el.typeField] ? el.typeField : "overVar",
            selectVars: el.selectVars,
          }))}
        />
      </SidebarBox>
    </ModalOvelay>
  );
};

export default InfoCMSFilterController;
