import { FC } from "react";

import { copyToBuffer } from "func/common";
import { SERVER_URL } from "func/constants";

interface EndpointInputProps {
  method: "GET" | "POST" | "DELETE";
  path: string;
  id?: string;
}

const EndpointInput: FC<EndpointInputProps> = ({
  method,
  path,
  id,
}: EndpointInputProps) => {
  return (
    <div className="d-flex align-items-baseline mb-3">
      <div className="input-group">
        <span className="input-group-text">{method}</span>
        <input
          type="text"
          className="form-control me-2 bg-white"
          value={id ? `${path}/${id}` : path}
          readOnly
        />
      </div>
      <i
        className="bi bi-files curs-poin me-2"
        onClick={(e) => {
          let url = path;
          if (id) url += `${id}`;
          copyToBuffer(SERVER_URL + url);
          (e.target as HTMLElement).classList.add("text-success");
          const timer = setTimeout(() => {
            (e.target as HTMLElement).classList.remove("text-success");
            clearTimeout(timer);
          }, 2000);
        }}
      />
      <i
        className="bi bi-info-circle"
        data-bs-toggle="tooltip"
        data-bs-placement="top"
        title="Тут должна быть информация"
      />
    </div>
  );
};

export default EndpointInput;
