import { FC } from "react";
import Select, {
  ActionMeta,
  DropdownIndicatorProps,
  GroupBase,
  IndicatorsContainerProps,
  MultiValue,
  OptionProps,
  StylesConfig,
  components,
} from "react-select";
import Button from "components/Button/Button";

import { ChevronDownIcon } from "icons";

import styles from "./Select.module.scss";

export const customStyles:
  | StylesConfig<string, true, GroupBase<string>>
  | undefined = {
  control: (provided, state) => ({
    ...provided,
    minHeight: "38px",
    maxHeight: "38px",
    borderRadius: "10px",
    borderColor: "#EAEAEA",
    transition: "border-color 0.15s ease-in-out",
    ":hover": {
      borderColor: "#C298EB",
    },
    "&:focus-within": {
      outline: "none",
      boxShadow: "0 0 0 1px #9B42F0",
    },
    cursor: "pointer",
    backgroundColor: state.isDisabled ? "#F7F8F9" : "#FFFFFF",
  }),
  valueContainer: (provided) => ({
    ...provided,
    padding: "0 6px",
  }),
  input: (provided) => ({
    ...provided,
    margin: "0px",
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: "0px 8px",
  }),
  menu: (provided) => ({
    ...provided,
    borderRadius: "10px",
    marginTop: "6px",
    border: "1px solid #F0D8FF",
    boxShadow: "0px 2px 6px 0px #00000014",
    padding: "4px",
  }),
  menuList: (provided) => ({
    ...provided,
    padding: "0px",
  }),
  option: (provided) => ({
    ...provided,
    padding: 0,
    backgroundColor: "transparent",
    ":active": {
      backgroundColor: "transparent",
    },
  }),
};

export const DropdownIndicator = (props: DropdownIndicatorProps<any, true>) => {
  return (
    <components.DropdownIndicator {...props}>
      <span
        style={{ color: props.selectProps.menuIsOpen ? "#9B42F0" : "unset" }}
      >
        <ChevronDownIcon />
      </span>
    </components.DropdownIndicator>
  );
};

export const IndicatorsContainer = (
  props: IndicatorsContainerProps<any, true>,
) => {
  return (
    <components.IndicatorsContainer {...props}>
      <DropdownIndicator {...(props as DropdownIndicatorProps<any, true>)} />
    </components.IndicatorsContainer>
  );
};

export const OptionComponent = (
  props: OptionProps<any, true, GroupBase<string>>,
) => {
  return (
    <components.Option {...props}>
      <Button
        className={`${styles["option"]}${
          props.isSelected ? ` ${styles["option--selected"]}` : ""
        }`}
        disabled={props.isDisabled}
      >
        {props.label}
        {props.isDisabled && props.data.disabledDescription && (
          <p className={styles["option__disabled"]}>
            {props.data.disabledDescription}
          </p>
        )}
      </Button>
    </components.Option>
  );
};

type Option = { value: string; label: string };

interface FilterSelectProps {
  options: Option[];
  placeholder: string;
  onChange:
    | ((newValue: MultiValue<any>, actionMeta: ActionMeta<any>) => void)
    | undefined;
  value?: Option | undefined;
  isControlled?: boolean;
  disabled: boolean;
}

const FilterSelect: FC<FilterSelectProps> = ({
  options,
  placeholder,
  onChange,
  value,
  disabled,
  isControlled,
}) => {
  return (
    <Select
      isDisabled={disabled}
      className={styles.select}
      styles={customStyles}
      options={options}
      hideSelectedOptions={false}
      placeholder={placeholder}
      closeMenuOnSelect
      onChange={onChange}
      menuPosition="fixed"
      value={isControlled ? value || null : undefined}
      defaultValue={value}
      components={{
        IndicatorSeparator: null,
        IndicatorsContainer,
        DropdownIndicator,
        MultiValue: () => null,
        Option: OptionComponent,
      }}
    />
  );
};

export default FilterSelect;
