import { FC, useState } from "react";

import styles from "./Dropdown.module.scss";
import { DropdownProps } from "components/Dropdown/DropdownInterface";
import Button from "components/Button/Button";

const useDropdownMenu = (ref: React.RefObject<HTMLDivElement>) => {
  const elementRect = ref.current?.getBoundingClientRect();

  const [isOpen, setIsOpen] = useState(false);

  const DropdownMenu: FC<DropdownProps> = ({ options, onOptionClick }) => {
    return (
      <>
        {isOpen && (
          <div
            className={styles["dropdown"]}
            style={{
              top: `${
                (elementRect?.top || 0) + (elementRect?.height || 0) + 6
              }px`,
              left: `${
                (elementRect?.left || 0) - 240 + (elementRect?.width || 0)
              }px`,
            }}
          >
            {options.map((option) => (
              <div
                key={option.id}
                className={styles["option"]}
              >
                <Button
                  childrenWrapperClassName={styles["option-button"]}
                  onClick={() => {
                    setIsOpen(false);
                    onOptionClick(option.id);
                  }}
                >
                  {option.name}
                </Button>
              </div>
            ))}
          </div>
        )}
      </>
    );
  };

  return {
    DropdownMenu,
    isOpen,
    setIsOpen,
  };
};

export default useDropdownMenu;
