import { ImageIcon } from "icons";
import type ICell from "interfaces/ICell";
import type ICellRenderStrategy from "interfaces/ICellRenderStrategy";

export class ImageCellRenderStrategy implements ICellRenderStrategy<ICell> {
  renderHeaderCell(data: ICell) {
    return (
      <div
        data-tooltip-id="data-table-tooltip"
        data-tooltip-content={data.name}
        data-tooltip-place="left"
      >
        <ImageIcon />
      </div>
    );
  }

  renderCell(data: ICell, props?: any): JSX.Element | null {
    const { value, styles } = props;

    return (
      <div
        className={`${styles["flex-cell"]} ${styles["icon-column"]} ${
          value !== null && value !== undefined && value?.length !== 0
            ? styles["flex-cell--with-content"]
            : ""
        }`}
      >
        <ImageIcon />
      </div>
    );
  }
}
