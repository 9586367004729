import { AppDispatch } from "../store";
import { resetToInitialState as resetFiltersToInitialState } from "../slices/filterSlice";
import { resetToInitialState as resetSortingToInitialState } from "../slices/sortingSlice";
import { resetToInitialState as resetVisibilityToInitialState } from "../slices/fieldVisibilitySlice";

export const resetFiltersSortingVisibilityToInitialState =
  () => (dispatch: AppDispatch) => {
    dispatch(resetFiltersToInitialState());
    dispatch(resetSortingToInitialState());
    dispatch(resetVisibilityToInitialState());
  };
