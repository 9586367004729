import { RefObject, useEffect } from "react";

function useOutsideClick<T extends HTMLElement>(
  triggerRef: RefObject<T>,
  callback: () => void,
  isActive: boolean
): void {
  useEffect(() => {
    const handleClick = (event: MouseEvent) => {
      if (
        isActive &&
        triggerRef.current &&
        !triggerRef.current.contains(event.target as Node)
      ) {
        callback();
      }
    };

    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, [triggerRef, callback, isActive]);
}

export default useOutsideClick;
