import { configureStore, combineReducers } from "@reduxjs/toolkit";
import modalReducer from "./slices/modalSlice";
import menuReducer from "./slices/menuSlice";
import collectionReducer from "store/reducers/collectionReducer";
import { apiService } from "api/apiService";
import { collectionApiService } from "api/collectionService";
import { apiKeyApiService } from "api/apiKeyService";

export const store = configureStore({
  reducer: {
    [apiService.reducerPath]: apiService.reducer,
    [collectionApiService.reducerPath]: collectionApiService.reducer,
    [apiKeyApiService.reducerPath]: apiKeyApiService.reducer,
    modal: modalReducer,
    menu: menuReducer,
    collection: collectionReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      apiService.middleware,
      collectionApiService.middleware,
      apiKeyApiService.middleware
    ),
});

// Infer the RootState and AppDispatch types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
