import { FC } from "react";

import styles from "./IconButton.module.scss";

type IconButtonProps = {
  onClick: (event: React.MouseEvent) => void;
  iconColor?: string;
} & React.PropsWithChildren;

const IconButton: FC<IconButtonProps> = ({ children, onClick, iconColor }) => {
  return (
    <button
      className={styles["button"]}
      onClick={onClick}
      style={{
        color: iconColor,
      }}
    >
      {children}
    </button>
  );
};

export default IconButton;
