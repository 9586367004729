import { stringifyFilters } from "utils/stringifyFilters";
import { RootState } from "../store";

export const getFiltersBody = (state: RootState) =>
  state.collection.filters.body;

export const selectAllFilters = (state: RootState) =>
  state.collection.filters.filters;

export const selectHaveChangesInFilters = (state: RootState) => {
  return (
    state.collection.filters.initialFiltersAsString !==
    stringifyFilters(state.collection.filters.filters)
  );
};
