/**
 * Returns the CSS class name for a column based on the column type.
 * @param type - The type of the column.
 * @param settingField - The setting field of the column (to split `slugifator` and `comparator`).
 * @param styles - The styles object containing the CSS class names.
 * @returns The CSS class name for the column.
 */
export function getColumnTypeClassName(
  type: string,
  settingField: string,
  styles: any
) {
  return type === "text" ||
    type === "boolean" ||
    type === "image" ||
    (type === "automat" && settingField !== "slugifator")
    ? styles["icon-column"] // RichText, Boolean or Image typeField
    : styles["content-column"];
}
