import { useState } from "react";

/**
 * Custom hook for deleting data using the specified API URL.
 *
 * @param apiUrl - The URL of the API endpoint.
 * @returns An object containing the deleteData function, isLoading state, and error state.
 */
const useDeleteData = (apiUrl: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const deleteData = async (id: string) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/${id}`, {
        method: "DELETE",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      return true;
    } catch (error) {
      setError(error as Error);
      throw error; // Rethrow for handling by the caller
    } finally {
      setIsLoading(false);
    }
  };

  return { deleteData, isLoading, error };
};

export default useDeleteData;
