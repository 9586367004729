import { RootState } from "store/store";

export const pageNameSelector = (state: RootState) =>
  state.collection.pageInfo.pageName;

export const collectionIdSelector = (state: RootState) =>
  state.collection.pageInfo.collectionId;

export const activeViewConfigurationIdSelector = (state: RootState) =>
  state.collection.pageInfo.currentViewConfigurationId;
