import { FC, useMemo } from "react";

import styles from "./InfoText.module.scss";

type InfoTextProps = {
  type: "info" | "error";
} & React.PropsWithChildren;

const InfoText: FC<InfoTextProps> = ({ children, type }) => {
  const className = useMemo(() => {
    return `${styles["info-text"]} ${styles[`info-text--${type}`]}`;
  }, [type]);

  return <span className={className}>{children}</span>;
};

export default InfoText;
