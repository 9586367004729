import { FC } from "react";
import {
  ApiIcon,
  ArrowRepeatIcon,
  CloudDownloadIcon,
  CollectionTypeIcon,
  GearIcon,
  HddIcon,
  HourglassIcon,
} from "icons";
import Button from "components/Button/Button";
import VerticalDivider from "components/VerticalDivider/VerticalDivider";

import RoundButton from "components/RoundButton/RoundButton";
import { useAppSelector } from "hooks/useAppSelector";
import {
  selectCurrentViewConfigurationId,
  selectHaveChangesInViewConfiguration,
} from "store/selectors/combinedSelectors/combinedSelector";
import { selectSortableFields } from "store/selectors/sortingSelectors";
import {
  selectViewConfigurationsResult,
  useLazyFetchViewConfigurationsQuery,
  useUpdateViewConfigurationMutation,
} from "api/apiService";
import { collectionIdSelector } from "store/selectors/collectionPageInfoSelectors";

import styles from "./CollectionHeader.module.scss";
import { selectVisibleFields } from "store/selectors/fieldVisibilitySelectors";
import { selectAllFilters } from "store/selectors/filterSelectors";
import { prepareViewConfigurationData } from "helpers/view-configurations";

interface CollectionHeaderProps {
  title: string;
  setEndPointsModalOpen: (value: React.SetStateAction<boolean>) => void;
  setExtractModalOpen: (value: React.SetStateAction<boolean>) => void;
  handleExportExcel: () => void;
  handleImportExcel: (e: React.ChangeEvent<HTMLInputElement>) => void;
  setExcelSettingsModalOpen: (value: React.SetStateAction<boolean>) => void;
}

const CollectionHeader: FC<CollectionHeaderProps> = ({
  title,
  setEndPointsModalOpen,
  setExtractModalOpen,
  handleExportExcel,
  handleImportExcel,
  setExcelSettingsModalOpen,
}) => {
  const collectionId = useAppSelector(collectionIdSelector);
  const haveChangesInViewConfiguration = useAppSelector(
    selectHaveChangesInViewConfiguration
  );
  const currentViewConfigurationId = useAppSelector(
    selectCurrentViewConfigurationId
  );
  const sortableFields = useAppSelector(selectSortableFields);
  const fieldVisibilities = useAppSelector(selectVisibleFields);
  const filters = useAppSelector(selectAllFilters);

  const [fetchViewConfigurations] = useLazyFetchViewConfigurationsQuery();
  const [updateViewConfiguration, { isLoading: isUpdatingConfiguration }] =
    useUpdateViewConfigurationMutation();
  const { data: configurations } = useAppSelector((state) =>
    selectViewConfigurationsResult(collectionId)(state)
  );
  const currentConfiguration = configurations?.data?.find(
    (config) => config._id === currentViewConfigurationId
  );

  const handleOnSaveViewClick = async () => {
    if (!currentConfiguration?._id) return;

    try {
      const payload = await updateViewConfiguration({
        viewConfigurationId: currentConfiguration._id,
        collectionId,
        body: {
          ...currentConfiguration,
          viewType: "list-view",
          ...prepareViewConfigurationData({
            filters,
            sortings: sortableFields,
            fieldVisibilities,
          }),
        },
      }).unwrap();

      if (payload) {
        await fetchViewConfigurations(collectionId);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles["left-column"]}>
        <h5 className={styles.title}>
          <div className={styles["icon-wrapper"]}>
            <CollectionTypeIcon />
          </div>
          <span>{title}</span>
        </h5>

        <RoundButton
          icon={isUpdatingConfiguration ? <HourglassIcon /> : <HddIcon />}
          style={{
            opacity: haveChangesInViewConfiguration ? 1 : 0,
            pointerEvents: haveChangesInViewConfiguration ? "all" : "none",
          }}
          onClick={handleOnSaveViewClick}
          disabled={isUpdatingConfiguration}
        >
          {isUpdatingConfiguration ? "Saving" : "Save Changes"}
        </RoundButton>
      </div>
      <div className="d-flex">
        <Button
          onClick={() => {
            setEndPointsModalOpen((isOpen: boolean) => !isOpen);
          }}
          icon={<ApiIcon />}
          condensed
        >
          API
        </Button>

        <VerticalDivider />

        <Button
          className={styles.button}
          onClick={() => {
            setExtractModalOpen((isOpen: boolean) => !isOpen);
          }}
          icon={<ArrowRepeatIcon />}
          condensed
        >
          Extract
        </Button>

        <Button
          className={styles.button}
          icon={<CloudDownloadIcon />}
          condensed
        >
          <span
            onClick={(e) => {
              e.stopPropagation();
              handleExportExcel();
            }}
          >
            Export
          </span>
          /
          <span
            onClick={(e) => {
              e.stopPropagation();
              document.getElementById("importFile")?.click();
            }}
          >
            Import
          </span>
          <input
            type="file"
            id="importFile"
            name="uploadImg"
            accept="application/vnd.ms-exce,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,.xlsb,.csv"
            onInput={handleImportExcel}
          />
        </Button>

        <Button
          onClick={() => {
            setExcelSettingsModalOpen((isOpen: boolean) => !isOpen);
          }}
          icon={<GearIcon />}
          condensed
        />
      </div>
    </div>
  );
};

export default CollectionHeader;
