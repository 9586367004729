/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import EmptyMedia from "./EmptyMedia";
import UploadMedia from "./UploadMedia";

const UploadMediaBlock = (props) => {
  const {
    activePic,
    getData,
    setEditMedia,
    isCollection,
    openChooseMedia,
    setActivePic,
    onDelete,
  } = props;

  const [currentPicture, setCurrentPicture] = useState(activePic);

  useEffect(() => {
    setCurrentPicture(activePic);
  }, [activePic]);

  return currentPicture ? (
    <UploadMedia
      state={props.state}
      changeUploadType={props.changeUploadType}
      currentPicture={currentPicture}
      getData={getData}
      setEditMedia={setEditMedia}
      isCollection={isCollection}
      openChooseMedia={openChooseMedia}
      setCurrentPicture={setCurrentPicture}
      onDelete={onDelete}
    />
  ) : (
    <EmptyMedia
      state={props.state}
      changeUploadType={props.changeUploadType}
      getData={getData}
      isCollection={isCollection}
      openChooseMedia={openChooseMedia}
      setActivePic={setActivePic}
    />
  );
};

export default UploadMediaBlock;
