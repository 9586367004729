import { useState } from "react";
import type { APIResponse } from "typings/apiState";

const usePutData = <Data>(apiUrl: string) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const putData = async (
    id: string,
    data: Data
  ): Promise<APIResponse<Data>> => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/${id}`, {
        method: "PUT",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const result = await response.json();
      return result;
    } catch (error) {
      setError(error as Error);
      throw error; // Rethrow for handling by the caller
    } finally {
      setIsLoading(false);
    }
  };

  return { putData, isLoading, error };
};

export default usePutData;
