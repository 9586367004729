import { FC } from "react";

const ContentTypeIcon: FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="22"
        height="22"
        rx="6"
        fill="#FDD8F2"
      />
      <path
        d="M4 15.375C4 15.7231 4.13828 16.0569 4.38442 16.3031C4.63056 16.5492 4.9644 16.6875 5.3125 16.6875H16.6875C17.0356 16.6875 17.3694 16.5492 17.6156 16.3031C17.8617 16.0569 18 15.7231 18 15.375V9.25C18 8.9019 17.8617 8.56806 17.6156 8.32192C17.3694 8.07578 17.0356 7.9375 16.6875 7.9375H5.3125C4.9644 7.9375 4.63056 8.07578 4.38442 8.32192C4.13828 8.56806 4 8.9019 4 9.25V15.375Z"
        fill="#DB15A3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 6.625C5.75 6.74103 5.79609 6.85231 5.87814 6.93436C5.96019 7.01641 6.07147 7.0625 6.1875 7.0625H15.8125C15.9285 7.0625 16.0398 7.01641 16.1219 6.93436C16.2039 6.85231 16.25 6.74103 16.25 6.625C16.25 6.50897 16.2039 6.39769 16.1219 6.31564C16.0398 6.23359 15.9285 6.1875 15.8125 6.1875H6.1875C6.07147 6.1875 5.96019 6.23359 5.87814 6.31564C5.79609 6.39769 5.75 6.50897 5.75 6.625ZM7.5 4.875C7.5 4.99103 7.54609 5.10231 7.62814 5.18436C7.71019 5.26641 7.82147 5.3125 7.9375 5.3125H14.0625C14.1785 5.3125 14.2898 5.26641 14.3719 5.18436C14.4539 5.10231 14.5 4.99103 14.5 4.875C14.5 4.75897 14.4539 4.64769 14.3719 4.56564C14.2898 4.48359 14.1785 4.4375 14.0625 4.4375H7.9375C7.82147 4.4375 7.71019 4.48359 7.62814 4.56564C7.54609 4.64769 7.5 4.75897 7.5 4.875Z"
        fill="#DB15A3"
      />
    </svg>
  );
};

export default ContentTypeIcon;
