import React from "react";
import switchCaseForFields from "./switchCaseForFields";

const ConstrBlocks = ({
  body,
  cols,
  data,
  dispatch,
  namesRefColl,
  setNamesRefColl,
  dataRefColl,
  setDataRefColl,
  funcOpenMedia,
  funcChooseFromMedia,
  isComponent = false,
  defaultsNames,
  onSave,
}) => {
  let colIndex = 0;
  let rowLengthComp = cols[colIndex] ? cols[colIndex].length : 1;
  let rowLengthCompCounter = rowLengthComp;
  const childsHTML = [];
  const refChildsHTML = [];
  let childsTimeHTML = [];

  body.forEach((el, i) => {
    if (rowLengthCompCounter === 0) {
      colIndex += 1;
      rowLengthComp = cols[colIndex] ? cols[colIndex].length : 1;
      rowLengthCompCounter = rowLengthComp;
      childsHTML.push(
        <div
          key={el.nameDB}
          style={{ marginBottom: "20px" }}
        >
          {childsTimeHTML}
        </div>
      );
      childsTimeHTML = [];
    }
    const emptyField = cols[colIndex]
      ? !cols[colIndex][rowLengthComp - rowLengthCompCounter]
      : false;
    rowLengthCompCounter -= 1;
    if (emptyField) {
      childsTimeHTML.push(<div className={`col-${12 / rowLengthComp}`}></div>);
    } else {
      childsTimeHTML.push(
        switchCaseForFields({
          body,
          data,
          dispatch,
          namesRefColl,
          setNamesRefColl,
          dataRefColl,
          setDataRefColl,
          activeIndex: i,
          rowLength: rowLengthComp,
          funcOpenMedia,
          funcChooseFromMedia,
          defaultsNames,
          onSave,
        })
      );
    }
  });

  childsHTML.push(
    <div
      key={"childsTimeHTML-wrapper"}
      style={{ marginBottom: "20px" }}
    >
      {childsTimeHTML}
    </div>
  );

  return [childsHTML, refChildsHTML];
};

export default ConstrBlocks;
