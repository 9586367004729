import { FC, useState, useMemo, useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import TableControlPanel from "pages/Collection/components/TableControlPanel/TableControlPanel";
import FiltersHeader from "pages/Collection/components/FiltersHeader/FiltersHeader";
import Button from "components/Button/Button";
import { useAppDispatch } from "hooks/useAppDispatch";
import { useAppSelector } from "hooks/useAppSelector";
import { COLLECTION_CONTROL_PANEL, PARAM_COLLECTION_ID } from "constants/index";
import FilterVariant from "pages/Collection/components/Filters/FilterVariant";
import FieldTypePicker from "components/FieldTypePicker/FieldTypePicker";
import HorizontalDivider from "components/HorizontalDivider/HorizontalDivider";

import { BorderlessPlusIcon } from "icons";

import type { CommonFilter, Field } from "typings/filter";
import styles from "./Filters.module.scss";
import iconsByType from "utils/iconsByType";
import FilterGroup from "pages/Collection/components/Filters/FilterGroup";
import { setActivePanel } from "store/slices/controlPanelSlice";
import AnimatedWrapper from "components/AnimatedWrapper/AnimatedWrapper";
import { selectActivePanel } from "store/selectors/controlPanelSelectors";
import {
  addFilterVariant,
  resetFiltersEditMode,
} from "store/slices/filterSlice";
import { getFilterableFieldTypes } from "helpers/getFilterableFieldTypes";

const Filters: FC = () => {
  const dispatch = useAppDispatch();
  const closeFilterBar = useCallback(
    () => dispatch(setActivePanel(null)),
    [dispatch]
  );

  const activePanel = useAppSelector(selectActivePanel);
  const isOpen = useMemo(
    () => activePanel === COLLECTION_CONTROL_PANEL.FILTERS,
    [activePanel]
  );

  const handleAddFilterClick = () => {
    dispatch(resetFiltersEditMode());
    setCurrentStep(2);
  };

  const [currentStep, setCurrentStep] = useState(1);

  const filters = useAppSelector((state) => state.collection.filters.filters);

  const isEmptyState = useMemo(() => {
    return filters.length === 0 && currentStep === 1;
  }, [filters.length, currentStep]);

  const [currentFilterGroup, setCurrentFilterGroup] = useState<string>("");

  const setInitalStep = useCallback(() => {
    setCurrentStep(1);
  }, [setCurrentStep]);

  const handleFieldClick = useCallback(
    (field: Field) => {
      const initialFilter: any = {
        fieldId: field._id,
        parentId: currentFilterGroup || null,
        id: uuidv4(),
        name: field.name,
        nameDB: field.nameDB,
        type: field.type,
        value: "",
        isInEditMode: true,
        isSaved: false,
        filterOption: "",
        isMulti: field.isMulti,
        refTo: field.refTo,
        additionalOptions: {
          settingField: field.settingField,
        },
        isGroup: false,
      };

      dispatch(
        addFilterVariant({
          filterVariant: initialFilter,
          filterGroupId: currentFilterGroup,
        })
      );

      setInitalStep();
    },
    [currentFilterGroup, dispatch, setInitalStep]
  );

  const fields = useAppSelector((state) =>
    getFilterableFieldTypes(state.collection.pageInfo.fields)
  );

  return (
    <AnimatedWrapper
      isOpen={isOpen}
      onClose={closeFilterBar}
    >
      <TableControlPanel
        headerComponent={
          <FiltersHeader
            title={currentStep === 1 ? "Filters" : "Add Field"}
            showToggleSwitch={currentStep === 1}
            showCancelButton={currentStep === 2}
            onCancel={setInitalStep}
            isFiltersEmpty={filters.length === 0}
          />
        }
        emptyStateComponent={
          <span className={styles["empty-state"]}>
            No active filter right now. Add first filter
          </span>
        }
        isEmpty={isEmptyState}
      >
        {currentStep === 1 && (
          <>
            {filters.map((filter) => {
              if (filter.isGroup) {
                return (
                  <FilterGroup
                    key={filter.id}
                    filterGroup={filter}
                    onAddFilterClick={() => {
                      setCurrentFilterGroup(filter.id);
                      dispatch(resetFiltersEditMode());
                      handleAddFilterClick();
                    }}
                  />
                );
              }

              return (
                <FilterVariant
                  key={filter.id}
                  filter={filter as CommonFilter}
                  icon={iconsByType[(filter as CommonFilter).type]}
                />
              );
            })}
            <HorizontalDivider
              style={{
                margin: isEmptyState ? "15px 0" : "10px 0",
              }}
            />
            <Button
              icon={<BorderlessPlusIcon />}
              squaredIcon
              onClick={() => {
                setCurrentFilterGroup("");
                handleAddFilterClick();
              }}
            >
              Add filter
            </Button>
          </>
        )}
        {currentStep === 2 && (
          <FieldTypePicker
            onFieldClick={handleFieldClick}
            icons={iconsByType}
            fields={fields}
          />
        )}
      </TableControlPanel>
    </AnimatedWrapper>
  );
};

export default Filters;
