import { FC, ReactElement, useState } from "react";
import { NavLink } from "react-router-dom";
import Button from "components/Button/Button";

import styles from "./Menu.module.scss";
import { determineIcon } from "utils/determineIcon";

interface SubMenuProps {
  menuName: string;
  icon: ReactElement;
  childElements: {
    name: string;
    path: string;
    type?: string;
    slug?: string;
  }[];
}

const SubMenu: FC<SubMenuProps> = ({ menuName, childElements, icon }) => {
  const [openSubMenus, setOpenSubMenus] = useState<string[]>([]);

  const handleSubMenuClick = (menuName: string) => {
    setOpenSubMenus(
      openSubMenus.includes(menuName)
        ? openSubMenus.filter((m) => m !== menuName)
        : [...openSubMenus, menuName]
    );
  };

  return (
    <>
      <Button
        icon={icon}
        onClick={() => handleSubMenuClick(menuName)}
        childrenWrapperClassName={styles["menu__button-text"]}
      >
        {menuName}
      </Button>
      {openSubMenus.includes(menuName) && (
        <div>
          {childElements.map((link) => (
            <NavLink
              to={link.path}
              key={link.path}
            >
              {({ isActive }) => (
                <Button
                  className={styles.menu__sub}
                  childrenWrapperClassName={
                    !isActive ? styles["menu__button-text"] : ""
                  }
                  isActive={isActive}
                  icon={link.type ? determineIcon(link.type) : null}
                >
                  {link.name}
                </Button>
              )}
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
};

export default SubMenu;
