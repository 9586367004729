import { Dispatch, SetStateAction, useCallback, useState } from "react";
import Button from "components/Button/Button";
import RowContextMenu from "components/RowContextMenu/RowContextMenu";
import { GripIcon } from "icons";
import type { Row as ReactTableRowType } from "@tanstack/react-table";

import styles from "./Table.module.scss";

export type ContextMenuOption = {
  label: string;
  action: (
    rowData: ReactTableRowType<any>,
    onCloseCallback: () => void
  ) => void;
  icon?: JSX.Element;
};

type ActionCellProps<TData> = {
  row: ReactTableRowType<TData>;
  activeRow: ReactTableRowType<TData>;
  setActiveRow: Dispatch<SetStateAction<ReactTableRowType<TData> | null>>;
  contextMenuOptions: ContextMenuOption[];
};

const ActionCell = <TData,>({
  row,
  activeRow,
  setActiveRow,
  contextMenuOptions,
}: ActionCellProps<TData>) => {
  const [isContextMenuActive, setIsContextMenuActive] = useState(false);
  const [clickPosition, setClickPosition] = useState({ x: 0, y: 0 });

  const closeContextMenu = useCallback(() => {
    setIsContextMenuActive(false);
    setActiveRow(null);
  }, [setActiveRow]);

  const handleGripClick = useCallback(
    (event: React.MouseEvent) => {
      setActiveRow(row);
      setIsContextMenuActive(true);

      // Get click position
      const clickPosition = { x: event.pageX, y: event.pageY };
      setClickPosition(clickPosition);
    },
    [setActiveRow, row]
  );

  return (
    <>
      <div
        className={styles["grip-button"]}
        onClick={handleGripClick}
      >
        <GripIcon />
      </div>
      {isContextMenuActive && (
        <RowContextMenu
          position={clickPosition}
          onClickOutside={closeContextMenu}
        >
          {contextMenuOptions.map((option) => (
            <Button
              key={option.label}
              icon={option.icon}
              onClick={() => {
                option.action(activeRow, closeContextMenu);
              }}
            >
              {option.label}
            </Button>
          ))}
        </RowContextMenu>
      )}
    </>
  );
};

export default ActionCell;
