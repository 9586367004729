import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activePanel: null,
};

const controlPanelSlice = createSlice({
  name: "controlPanel",
  initialState,
  reducers: {
    setActivePanel: (state, action) => {
      state.activePanel = action.payload;
    },
  },
});

export const { setActivePanel } = controlPanelSlice.actions;
export default controlPanelSlice.reducer;
