import { useCallback, useEffect } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "hooks/useAppDispatch";
import { updateMenu } from "store/slices/menuSlice";
import api from "func/api";

type Menu = {
  menu: unknown[];
  slug: string;
  itemsPerPage: string | null;
  pathname: string;
  search: string | null;
};

const buildMenu = ({ menu, slug, itemsPerPage, pathname, search }: Menu) => {
  return menu?.map((item: any) => {
    const _menu = {
      ...item,
    };

    if (!item.isFolder && item.path) {
      _menu.path = `front/${slug}${item.path}`;
    }

    const children = item.children?.map((el: any) => {
      const isActive = pathname?.includes(el.path);

      return {
        name: el.name,
        path: `front/${slug}${el.path}${
          el.type === "collection"
            ? isActive
              ? `${search}`
              : `?page=1&itemsPerPage=${itemsPerPage || 20}`
            : ""
        }`,
        type: el.type,
      };
    });

    if (children?.length > 0) {
      _menu.children = children;
    }

    return _menu;
  });
};

const useDynamicMenu = (slug: string) => {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const itemsPerPage = searchParams.get("itemsPerPage");
  const location = useLocation();

  const updateDynamicMenu = useCallback(() => {
    api.getSettingsSelect("menu").then((response: any) => {
      const dynamicMenu = buildMenu({
        menu: response?.settings?.menu,
        slug,
        itemsPerPage,
        pathname: location.pathname,
        search: location.search,
      });

      dispatch(updateMenu(dynamicMenu));
    });
  }, [itemsPerPage, dispatch, location.pathname, location.search, slug]);

  useEffect(() => {
    updateDynamicMenu();
  }, [updateDynamicMenu]);

  return { updateDynamicMenu };
};

export default useDynamicMenu;
