import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import Form, { Field } from "rc-field-form";
import { format } from "date-fns";

import {
  useCreateApiKeyMutation,
  useDeleteApiKeyMutation,
  useFetchApiKeysQuery,
  useUpdateApiKeyNameMutation,
} from "api/apiKeyService";

import debounce from "utils/debounce";

import Button from "components/Button/Button";
import AnimatedWrapper from "components/AnimatedWrapper/AnimatedWrapper";
import TableControlHeader from "components/TableControlHeader/TableControlHeader";
import TableControlPanel from "pages/Collection/components/TableControlPanel/TableControlPanel";
import HorizontalDivider from "components/HorizontalDivider/HorizontalDivider";
import Table from "components/Table/Table";
import { ContextMenuOption } from "components/Table/ActionCell";
import Input from "components/Input/Input";
import RoundButton from "components/RoundButton/RoundButton";
import Label from "components/Label/Label";
import IconButton from "components/IconButton/IconButton";
import InfoText from "components/InfoText/InfoText";
import Pagination from "components/Pagination/Pagination";
import EmptyState from "components/EmptyState/EmptyState";

import { CopyIcon, KeyIcon, PlusIconWithoutRect, TrashIcon } from "icons";
import { ReactComponent as LockSVG } from "img/lock.svg";

import type { ColumnDef, CellContext } from "@tanstack/react-table";

import styles from "./Apikeys.module.scss";

type User = {
  active: boolean;
  cmsAccess: { main: boolean };
  email: string;
  name: string;
  phone: string;
  role: string;
  username: string;
  __v: number;
  _id: string;
};

export type APIKey = {
  name: string;
  createdAt: string;
  createdBy: User;
  id: string;
};

const columns: ColumnDef<APIKey>[] = [
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    accessorKey: "createdAt",
    header: "Created",
    cell: (props: CellContext<APIKey, any>) =>
      format(props.getValue(), "yyyy-MM-dd HH:mm"),
  },
  {
    accessorKey: "createdBy.name",
    header: "Created by",
  },
];

const fallbackData: APIKey[] = [];

const Apikeys: FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const currentPage = useMemo(() => {
    const page = searchParams.get("page");
    return page ? parseInt(page) : 1;
  }, [searchParams]);

  const itemsPerPage = useMemo(() => {
    const itemsPerPageAmount = searchParams.get("itemsPerPage");
    return itemsPerPageAmount ? parseInt(itemsPerPageAmount) : 20;
  }, [searchParams]);

  const { data, error, isLoading, isFetching } = useFetchApiKeysQuery({
    params: { page: currentPage, limit: itemsPerPage },
  });

  const totalItems = useMemo(() => data?.data?.totalItems ?? 0, [data]);

  const [wasAddButtonClicked, setWasAddButtonClicked] = useState(false);

  const [
    createApiKey,
    {
      isLoading: isApiKeyCreating,
      isSuccess: wasCreatingKeySuccessful,
      isError: wasErrorWhileCreatingKeyOccured,
      status: createApiKeyStatus,
      data: createdKeyData,
    },
  ] = useCreateApiKeyMutation();
  const [
    updateApiKeyName,
    {
      isLoading: isApiKeyUpdating,
      isSuccess: wasUpdatingKeySuccessful,
      isError: wasErrorWhileUpdatingKeyOccured,
    },
  ] = useUpdateApiKeyNameMutation();
  const [
    deleteApiKey,
    {
      isLoading: isApiKeyDeleting,
      isSuccess: wasDeletingKeySuccessful,
      isError: wasErrorWhileDeletingKeyOccured,
    },
  ] = useDeleteApiKeyMutation();

  const contextMenuOptions: ContextMenuOption[] = useMemo(
    () => [
      {
        label: "Revoke",
        icon: <TrashIcon />,
        action: async (rowData: any, onCloseCallback) => {
          console.log("Revoke action", rowData.original.id, onCloseCallback);
          try {
            await deleteApiKey({ id: rowData.original.id }).unwrap();
          } catch (error) {
            console.error("Error while deleting API key", error);
          } finally {
            onCloseCallback();
          }
        },
      },
    ],
    [deleteApiKey]
  );

  const [buttonAppearance, setButtonAppearance] = useState<
    "inverted" | "positive" | "negative"
  >("inverted");
  const [buttonText, setButtonText] = useState<
    "Add" | "Adding" | "Added" | "Try later"
  >("Add");

  const onAddButtonClick = useMemo(() => {
    return debounce(async () => {
      try {
        await createApiKey().unwrap();
      } catch (error) {
        console.error("Error while creating API key", error);
      } finally {
        setWasAddButtonClicked(false);
      }
    }, 500);
  }, [createApiKey]);

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (isApiKeyCreating) {
      setButtonText("Adding");
    }
    if (!isLoading && createApiKeyStatus !== "pending") {
      if (wasCreatingKeySuccessful) {
        setButtonText("Added");
        setButtonAppearance("positive");
        setIsOpen(true);
      } else if (wasErrorWhileCreatingKeyOccured) {
        setButtonText("Try later");
        setButtonAppearance("negative");
      }

      timer = setTimeout(() => {
        setButtonText("Add");
        setButtonAppearance("inverted");
        clearTimeout(timer);
      }, 1200);
    }

    return () => {
      clearTimeout(timer);
    };
  }, [
    isLoading,
    wasCreatingKeySuccessful,
    wasErrorWhileCreatingKeyOccured,
    createApiKeyStatus,
    isApiKeyCreating,
  ]);

  const [isOpen, setIsOpen] = useState(false);

  const [currentCopyIconState, setCurrentCopyIconState] = useState<
    "success" | "initial" | "error"
  >("initial");

  const currentCopyIconColor = useMemo(
    () =>
      currentCopyIconState === "success"
        ? "#27AE60"
        : currentCopyIconState === "error"
        ? "#F04343"
        : "#9B42F0",
    [currentCopyIconState]
  );

  const copyToClipboard = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();
      try {
        if (createdKeyData?.data?.key && navigator?.clipboard?.writeText) {
          await navigator.clipboard.writeText(createdKeyData.data.key);
          setCurrentCopyIconState("success");
        }
      } catch (error) {
        console.error("Error while copying to clipboard", error);
        setCurrentCopyIconState("error");
      } finally {
        setTimeout(() => {
          setCurrentCopyIconState("initial");
        }, 1200);
      }
    },
    [createdKeyData?.data?.key]
  );

  const onSidebarClose = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  const onFormFinish = useCallback(
    async (values: { name: string; secretKey: string }) => {
      try {
        if (!createdKeyData?.data?.id) return;

        await updateApiKeyName({
          id: createdKeyData?.data?.id,
          name: values.name,
        }).unwrap();
      } catch (error) {
        console.error("Error while updating API key name", error);
      } finally {
        setIsOpen(false);
      }
    },
    [createdKeyData?.data?.id, updateApiKeyName, setIsOpen]
  );

  const shouldAddPreloadingStyle = useMemo(
    () =>
      isFetching || isApiKeyCreating || wasAddButtonClicked || isApiKeyDeleting,
    [isFetching, isApiKeyCreating, wasAddButtonClicked, isApiKeyDeleting]
  );

  const onAddButtonClickHandler = useCallback(() => {
    setIsOpen(false);
    setWasAddButtonClicked(true);
    setButtonText("Adding");
    onAddButtonClick();
  }, [setIsOpen, setWasAddButtonClicked, setButtonText, onAddButtonClick]);

  const apiKeys = data?.data?.items || fallbackData;
  const shouldShowEmptyState = useMemo(
    () => apiKeys.length === 0 && !isLoading,
    [isLoading, apiKeys.length]
  );

  return (
    <main className={styles["main"]}>
      <div className={styles["content"]}>
        <h5
          className={styles["title"]}
          style={{ marginBottom: 30 }}
        >
          <div className={styles["icon"]}>
            <KeyIcon />
          </div>
          <span>API Keys</span>
        </h5>
        <div className={styles["wrapper"]}>
          <div className={styles["table-views-wrapper"]}>
            <div className={styles["view-name"]}>All keys</div>
            <div>
              <Button
                className={styles["button"]}
                icon={<PlusIconWithoutRect />}
                onClick={onAddButtonClickHandler}
                isLoading={isApiKeyCreating || wasAddButtonClicked}
                appearance={buttonAppearance}
              >
                {buttonText}
              </Button>
            </div>
          </div>
          <div
            style={{
              opacity: shouldAddPreloadingStyle ? 0.5 : 1,
              pointerEvents: shouldAddPreloadingStyle ? "none" : "auto",
              height: "calc(100% - 40px)",
            }}
          >
            {shouldShowEmptyState && (
              <EmptyState description="It’s feel lonely here. Let’s add first API Key">
                <LockSVG />
              </EmptyState>
            )}
            <Table<APIKey>
              columns={columns}
              data={apiKeys}
              contextMenuOptions={contextMenuOptions}
            />
          </div>
          <AnimatedWrapper
            isOpen={wasCreatingKeySuccessful && isOpen}
            onClose={onSidebarClose}
          >
            <TableControlPanel
              headerComponent={
                <TableControlHeader
                  title="🔑 New Key"
                  onClose={onSidebarClose}
                />
              }
              dividerMargins="15px 0"
            >
              <p
                style={{
                  padding: "0 8px",
                  fontSize: "14px",
                  color: "#989898",
                }}
              >
                Key will give full access to CMS resources. It’s visible only
                now. Give it a name if needed.
              </p>
              <HorizontalDivider
                style={{
                  margin: "15px 0",
                }}
              />
              <Form
                onFinish={onFormFinish}
                initialValues={{
                  name: "",
                  secretKey: createdKeyData?.data?.key || "",
                }}
              >
                <Label
                  htmlFor="name"
                  label="Name"
                  style={{
                    marginBottom: 20,
                  }}
                >
                  <Field name="name">
                    <Input placeholder="Secret Key" />
                  </Field>
                </Label>
                <Label
                  htmlFor="secretKey"
                  label="Key"
                  style={{
                    marginBottom: 30,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Field name="secretKey">
                      <Input disabled />
                    </Field>
                    <IconButton
                      onClick={copyToClipboard}
                      iconColor={currentCopyIconColor}
                    >
                      <CopyIcon />
                    </IconButton>
                  </div>
                  <InfoText type="error">
                    This token can’t be viewed later
                  </InfoText>
                </Label>
                <RoundButton
                  size="full-width"
                  disabled={isApiKeyUpdating}
                >
                  Save and close
                </RoundButton>
              </Form>
            </TableControlPanel>
          </AnimatedWrapper>
        </div>
      </div>
      <Pagination
        currentPage={currentPage}
        totalItems={totalItems}
        itemsPerPage={itemsPerPage}
      />
    </main>
  );
};

export default Apikeys;
