/* eslint-disable react/prop-types */
const ExcelProgress = ({
  dowloadProgress,
  statusImportExcel,
  setImportPopupExcel,
}) => {
  return (
    <div
      className="modal fade show"
      tabIndex="-1"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Прогресс</h5>
          </div>
          <div className="modal-body">
            {statusImportExcel === "download" && (
              <p>
                Загрузка файла {dowloadProgress}% <br />{" "}
              </p>
            )}
            {statusImportExcel === "wait" && (
              <p>Обновление в очереди, подождите</p>
            )}
            {(statusImportExcel === "start" ||
              statusImportExcel === "start2") && (
              <p>Происходит обновление данных... Ожидайте</p>
            )}
            {statusImportExcel === "finish" && (
              <p>Происходит обновление связанных коллекций... Ожидайте</p>
            )}
            {statusImportExcel !== "close" &&
              statusImportExcel !== "finish" && (
                <b>Не закрывайте данное окно и не перезагружайте страницу!</b>
              )}
            {statusImportExcel === "close" && <p>Импорт завершен</p>}
          </div>
          {(statusImportExcel === "close" ||
            statusImportExcel === "finish") && (
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-secondary"
                data-bs-dismiss="modal"
                onClick={() => {
                  setImportPopupExcel(false);
                }}
              >
                Close
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExcelProgress;
