export function getScrollbarWidth() {
  // Create outer block
  const outer = document.createElement("div");
  outer.style.visibility = "hidden";
  outer.style.overflow = "scroll"; // Force scrollbar to appear
  (outer.style as any).msOverflowStyle = "scrollbar"; // Need for IE and Edge
  document.body.appendChild(outer);

  // Create inner block
  const inner = document.createElement("div");
  outer.appendChild(inner);

  // Calculate width of the scrollbar
  const scrollbarWidth = outer.offsetWidth - inner.offsetWidth;

  // Delete outer block
  outer.parentNode?.removeChild(outer);

  return scrollbarWidth;
}
