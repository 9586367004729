import {
  FC,
  useEffect,
  useState,
  useMemo,
  type SetStateAction,
  type Dispatch,
} from "react";
import { SearchIcon } from "icons";

import styles from "./BigSearch.module.scss";
import CloseButton from "components/CloseButton/CloseButton";

interface BigSearchProps {
  onSearch: Dispatch<SetStateAction<string>>;
  placeholder: string;
  className?: string;
}

const BigSearch: FC<BigSearchProps> = ({
  onSearch,
  className,
  placeholder,
}) => {
  const [value, setValue] = useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  useEffect(() => {
    onSearch(value);
  }, [onSearch, value]);

  const handleSearchCancel = () => {
    setValue("");
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setValue("");
      }
    };

    window.addEventListener("keydown", handleKeyDown);

    // Удаляем слушатель, когда компонент размонтируется
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const doesValueExist = useMemo(() => {
    return value.length > 0;
  }, [value]);

  const wrapperClassName = `${styles.wrapper}${
    className ? ` ${className}` : ""
  }${doesValueExist ? ` ${styles.wrapper_active}` : ""}`;

  return (
    <div className={wrapperClassName}>
      <div className={styles.container}>
        <div className={styles.icon}>
          {doesValueExist ? (
            <CloseButton
              onClose={handleSearchCancel}
              isActive={true}
            />
          ) : (
            <div className={`${styles.icon} ${styles.icon_search}`}>
              <SearchIcon />
            </div>
          )}
        </div>
        <input
          className={styles.input}
          type="text"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
};

export default BigSearch;
