/* eslint-disable react/prop-types */

import type ICell from "interfaces/ICell";
import type ICellRenderStrategy from "interfaces/ICellRenderStrategy";
import Tag from "pages/Collection/components/DataTable/Tag";
import { Link } from "react-router-dom";

export class RefCellRenderStrategy implements ICellRenderStrategy<ICell> {
  renderHeaderCell(data: ICell) {
    return <>{data.name}</>;
  }

  renderCell(data: ICell, props?: any): JSX.Element | null {
    const { value, styles } = props;
    const { columnSchema } = props.column.columnDef.meta;

    const handleOnClick = (
      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
    ) => {
      event.stopPropagation();
    };

    const Component = (props: any) => {
      return (
        <Link
          to={`/front/main/collections/${columnSchema.refTo}/${props?.id}`}
          onClick={handleOnClick}
        >
          <Tag withUnderline>{props.name || props.id}</Tag>
        </Link>
      );
    };

    return (
      <div className={`${styles["flex-cell"]} ${styles["content-column"]}`}>
        {value?._id ? (
          <Component
            id={value._id}
            name={value.name}
          />
        ) : value?.length > 0 && Array.isArray(value) ? (
          value?.map((element: any) => (
            <Component
              key={element?._id}
              id={element?._id}
              name={element?.name}
            />
          ))
        ) : (
          "—"
        )}
      </div>
    );
  }
}
