import { SERVER_URL, API_VERSION } from "func/constants";

interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget;
}

interface ImageFormat {
  height: string;
  name: string;
  size: number;
  url: string;
  width: string;
}

interface ImageInfo {
  alt: string;
  dateCreate: string;
  ext: string;
  formats: {
    large: ImageFormat;
    medium: ImageFormat;
    small: ImageFormat;
    thumbnail: ImageFormat;
  };
  hash: string;
  height: string;
  infoCMS: string;
  mime: string;
  name: string;
  provider: string;
  size: number;
  updatedAt: string;
  url: string;
  whereUsed: any[];
  width: string;
  __v: number;
  _id: string;
}

interface UploadImageProps {
  event: HTMLInputEvent;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
  setThumbnail: React.Dispatch<React.SetStateAction<ImageInfo>>;
  loadAllImagesCallback: (arg?: any) => void;
}

/**
 * Uploads an image to the server. This function is implemented using XMLHttpRequest due to necessity of progress bar implementation.
 * @param event - The HTML input event containing the selected file.
 * @param setProgress - The state setter for the upload progress.
 * @param setThumbnail - The state setter for the thumbnail image.
 * @param loadAllImagesCallback - The callback function to load all images after successful upload.
 * @throws {Error} If no file is selected or if CMS slug is not found.
 */
export const uploadImage = ({
  event,
  setProgress,
  setThumbnail,
  loadAllImagesCallback,
}: UploadImageProps): Promise<void> => {
  return new Promise((resolve, reject) => {
    if (!event.target.files || !event.target.files.length) {
      return reject("No file");
    }

    let cmsSlug = null;
    if (window.location.pathname.includes("/front/")) {
      let path = window.location.pathname.split("/front/");
      path.shift();

      if (path.length) {
        cmsSlug = path[0].split("/")[0];
      }
    }
    if (!cmsSlug) throw new Error("CMS slug not found");

    const formData = new FormData();
    formData.append("uploadImg", event.target.files[0]);

    const xhr = new XMLHttpRequest();
    xhr.withCredentials = true;

    xhr.open(
      "POST",
      `${SERVER_URL}/api/${API_VERSION}/${cmsSlug}/upload-media`
    );

    xhr.upload.onprogress = (event) => {
      if (setProgress) {
        setProgress(Math.round((event.loaded / event.total) * 100));
      }
    };

    xhr.onloadend = () => {
      if (xhr.status == 200) {
        try {
          const body = JSON.parse(xhr.response);
          if (loadAllImagesCallback) {
            loadAllImagesCallback(body);
          }
          if (setThumbnail && body && body.length === 1 && body[0]) {
            setThumbnail(body[0]);
          }
          resolve();
        } catch (error) {
          reject(new Error("Error processing response: " + error));
        }
      } else {
        reject(new Error("Error uploading image"));
      }
    };

    xhr.send(formData);
  });
};
