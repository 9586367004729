import { FC, Fragment, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { flexRender, type Row as RowType } from "@tanstack/react-table";

import styles from "./DataTable.module.scss";

interface RowProps {
  row: RowType<any>;
  handleDeleteRow: (rowId: string) => void;
}

const Row: FC<RowProps> = ({ row, handleDeleteRow }) => {
  const [isRowActive, setIsRowActive] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const handleRowClick = () => {
    const basePath = location.pathname;
    const productId = row.original._id;

    navigate(`${basePath}/${productId}`);
  };

  return (
    <div
      className={`${styles["flex-row"]} ${isRowActive ? styles["active"] : ""}`}
      onClick={handleRowClick}
    >
      {row.getVisibleCells().map((cell) => {
        return (
          <Fragment key={cell.id}>
            {flexRender(cell.column.columnDef.cell, {
              ...cell.getContext(),
              setIsRowActive,
              handleDeleteRow,
            })}
          </Fragment>
        );
      })}
    </div>
  );
};

export default Row;
