import { Dispatch, FC, SetStateAction, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router";

import Button from "components/Button/Button";
import { ArrowCounterClockwiseIcon, PlusIconWithoutRect } from "icons";

import styles from "./CollectionNavbar.module.scss";
import ToolsPanel from "pages/Collection/components/ToolsPanel/ToolsPanel";
import ViewConfigurations from "pages/Collection/components/ViewConfigurations/ViewConfigurations";
import { useAppSelector } from "hooks/useAppSelector";
import { selectHaveChangesInViewConfiguration } from "store/selectors/combinedSelectors/combinedSelector";
import { useAppDispatch } from "hooks/useAppDispatch";
import { resetFiltersSortingVisibilityToInitialState } from "store/thunks/resetToInitialState";

interface CollectionNavbarProps {
  collectionId: string | undefined;
}

const CollectionNavbar: FC<CollectionNavbarProps> = ({ collectionId }) => {
  const navigate = useNavigate();

  const navigateToNewCollection = useCallback(() => {
    navigate(`${collectionId}/new`);
  }, [navigate, collectionId]);

  const haveChangesInViewConfiguration = useAppSelector(
    selectHaveChangesInViewConfiguration
  );

  const dispatch = useAppDispatch();
  const resetToInitialState = useCallback(() => {
    dispatch(resetFiltersSortingVisibilityToInitialState());
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <ViewConfigurations />
      <div className={styles["right-side"]}>
        {haveChangesInViewConfiguration && (
          <Button
            className={`${styles["button"]} ${styles["reset-button"]}`}
            icon={<ArrowCounterClockwiseIcon />}
            onClick={resetToInitialState}
          >
            Reset
          </Button>
        )}
        <ToolsPanel />
        <Button
          className={styles["button"]}
          icon={<PlusIconWithoutRect />}
          inverted
          onClick={navigateToNewCollection}
        >
          Add
        </Button>
      </div>
    </div>
  );
};

export default CollectionNavbar;
