import { FC, PropsWithChildren, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import useOutsideClick from "hooks/useOutsideClick";

import styles from "./RowContextMenu.module.scss";

interface RowContextMenuProps extends PropsWithChildren {
  position: {
    x: number;
    y: number;
  };
  onClickOutside: () => void;
}

const RowContextMenu: FC<RowContextMenuProps> = ({
  children,
  position,
  onClickOutside,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [menuWidth, setMenuWidth] = useState(0);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (ref.current) {
      setMenuWidth(ref.current.offsetWidth);
      setIsActive(true);
    }
  }, []);

  useOutsideClick(
    ref,
    () => {
      setIsActive(false);
      onClickOutside();
    },
    isActive
  );

  return ReactDOM.createPortal(
    <div
      ref={ref}
      className={`${styles.wrapper} ${isActive ? styles["active"] : ""}`}
      style={{
        left: position.x - menuWidth,
        top: position.y,
      }}
    >
      {children}
    </div>,
    document.body
  );
};

export default RowContextMenu;
