import { FC } from "react";

const SinglePageTypeIcon: FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="22"
        height="22"
        rx="6"
        fill="#FDD8F2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.75 5.75C5.75 5.28587 5.93437 4.84075 6.26256 4.51256C6.59075 4.18437 7.03587 4 7.5 4H12.1314C12.3634 4.00005 12.5859 4.09227 12.75 4.25638L15.9936 7.5C16.1577 7.66406 16.25 7.88658 16.25 8.11862V16.25C16.25 16.7141 16.0656 17.1592 15.7374 17.4874C15.4092 17.8156 14.9641 18 14.5 18H7.5C7.03587 18 6.59075 17.8156 6.26256 17.4874C5.93437 17.1592 5.75 16.7141 5.75 16.25V5.75ZM12.3125 7.0625V5.3125L14.9375 7.9375H13.1875C12.9554 7.9375 12.7329 7.84531 12.5688 7.68122C12.4047 7.51712 12.3125 7.29456 12.3125 7.0625ZM10.1688 14.3994C10.1688 15.6375 9.68925 16.3296 8.83525 16.3296C7.9795 16.3287 7.5 15.6375 7.5 14.3994C7.5 13.1639 7.9795 12.4718 8.83525 12.4718C9.68925 12.4718 10.1688 13.163 10.1688 14.3994ZM8.83525 12.9889C8.361 12.9889 8.10725 13.4815 8.10725 14.3994C8.10725 14.4764 8.10987 14.5507 8.1125 14.6199L9.47662 13.6198C9.36637 13.205 9.1485 12.9897 8.83525 12.9897V12.9889ZM8.19475 15.1834C8.305 15.5964 8.52025 15.8116 8.83525 15.8116C9.30775 15.8116 9.5615 15.319 9.5615 14.3994C9.5615 14.325 9.55887 14.2506 9.55625 14.1806L8.19475 15.1834ZM13.4981 15.7294V16.25H10.8731V15.7294H11.9065V13.1875H11.8628L10.8688 13.8411V13.2391L11.91 12.5514H12.5137V15.7303H13.4981V15.7294Z"
        fill="#DB15A3"
      />
    </svg>
  );
};

export default SinglePageTypeIcon;
