import { FC, PropsWithChildren, useRef } from "react";
import useDropdownMenu from "components/Dropdown/useDropdownMenu";
import ChevronDownIcon from "icons/ChevronDownIcon";
import type { DropdownProps } from "components/Dropdown/DropdownInterface";

import styles from "./Dropdown.module.scss";
import useOutsideClick from "hooks/useOutsideClick";

const Dropdown: FC<PropsWithChildren<DropdownProps>> = ({
  children,
  options,
  onOptionClick,
}) => {
  const dropdownRef = useRef<HTMLDivElement>(null);
  const { DropdownMenu, isOpen, setIsOpen } = useDropdownMenu(dropdownRef);

  const wrapperRef = useRef<HTMLDivElement>(null);
  useOutsideClick(
    wrapperRef,
    () => {
      setIsOpen(false);
    },
    isOpen
  );

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const dropdownTriggerClassName = `${styles["trigger"]}${
    isOpen ? ` ${styles["trigger_active"]}` : ""
  }`;

  return (
    <div ref={wrapperRef}>
      <div
        ref={dropdownRef}
        className={dropdownTriggerClassName}
        onClick={handleClick}
      >
        <span className={styles["text"]}>{children}</span>
        <ChevronDownIcon />
      </div>
      <DropdownMenu
        options={options}
        onOptionClick={onOptionClick}
      />
    </div>
  );
};

export default Dropdown;
