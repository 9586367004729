import { FC } from "react";

import styles from "./EmptyState.module.scss";

type EmptyStateProps = {
  description: string;
} & React.PropsWithChildren;

const EmptyState: FC<EmptyStateProps> = ({ children, description }) => {
  return (
    <div className={styles["empty-state"]}>
      {children}
      <span>{description}</span>
    </div>
  );
};

export default EmptyState;
