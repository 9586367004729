import removeEmptyObjectsAndReturnCopy from "utils/removeEmptyObjectsAndReturnCopy";
import { SERVER_URL, API_VERSION } from "./constants";

// Определяем базовый тип для всех ответов JSend
interface JSendBaseResponse {
  status: "success" | "fail" | "error";
}

// Тип для успешного ответа
interface JSendSuccessResponse<T> extends JSendBaseResponse {
  status: "success";
  data: T; // Дженерик тип для данных ответа
}

// Тип для ответа с ошибкой на стороне сервера (например, ошибка валидации)
interface JSendFailResponse<T> extends JSendBaseResponse {
  status: "fail";
  data: T; // Дженерик тип для данных об ошибке
}

// Тип для ответа с ошибкой выполнения (например, исключение)
interface JSendErrorResponse extends JSendBaseResponse {
  status: "error";
  message: string; // Сообщение об ошибке
  code?: number; // Необязательный код ошибки
  data?: any; // Дополнительные данные об ошибке
}

interface fetchConstructorProps {
  serverUrl?: string;
  type: string;
  cmsSlug?: string;
  path?: string;
  method: "POST" | "GET" | "DELETE" | "PUT";
  data?: object;
  contentType?: string;
  params?: Record<string, string> | undefined;
}

/**
 * @typedef fetchProperty
 *
 * @property {string} [serverUrl]
 * @property {string} type
 * @property {string} [cmsSlug]
 * @property {string} [path]
 * @property {'POST'|'GET'|'DELETE'|'PUT'} method
 * @property {object} [data]
 * @property {string} [contentType]
 */

class Api {
  cmsData = {
    slug: "",
  };

  constructor() {
    if (window.location.pathname.includes("/front/")) {
      let path = window.location.pathname.split("/front/");
      path.shift();

      if (path.length) {
        path = path[0].split("/");
      }

      this.cmsData.slug = path[0];
    }
  }

  async fetchConstructorAsJSend<T>({
    serverUrl = SERVER_URL,
    type,
    cmsSlug = this.cmsData.slug,
    path,
    method,
    data,
    params,
    contentType = "application/json",
  }: fetchConstructorProps): Promise<
    JSendSuccessResponse<T> | JSendFailResponse<T> | JSendErrorResponse
  > {
    try {
      const settings: RequestInit = {
        method: method,
        credentials: "include",
        headers: new Headers({
          "Content-Type": contentType,
        }),
        body: null,
      };

      if (data) settings.body = JSON.stringify(data);

      let url = `${serverUrl}/api/${API_VERSION}/${cmsSlug || ""}/${type}/${
        path || ""
      }`;

      // Создаем объект URLSearchParams
      let queryParams = new URLSearchParams(params).toString();

      if (queryParams) {
        // Проверяем, содержит ли URL уже параметры запроса
        if (url.includes("?")) {
          // Если да, добавляем новые параметры с помощью '&'
          url += "&" + queryParams;
        } else {
          // Если нет, добавляем новые параметры с помощью '?'
          url += "?" + queryParams;
        }
      }

      const response = await fetch(url, settings);

      return await response.json();
    } catch (err) {
      console.error(err);
      return { status: "error", message: "Unknown Error" };
    }
  }

  async fetchConstructor({
    serverUrl = SERVER_URL,
    type,
    cmsSlug = this.cmsData.slug,
    path,
    method,
    data,
    contentType = "application/json",
  }: any) {
    const settings: RequestInit = {
      method: method,
      credentials: "include",
      headers: new Headers({
        "Content-Type": contentType,
      }),
      body: null,
    };

    if (data) settings.body = JSON.stringify(data);

    try {
      const res = await fetch(
        `${serverUrl}/api/${API_VERSION}/${cmsSlug || ""}/${type}/${
          path || ""
        }`,
        settings,
      );

      const json = await res.json();
      if (res.ok) {
        return json;
      }
    } catch (err) {
      console.error(err);
    }
  }

  postConstr(data: any) {
    return this.fetchConstructor({
      type: "schema",
      path: "collection",
      method: "POST",
      data,
    });
  }

  getLockTypes() {
    return this.fetchConstructor({
      type: "schema",
      path: "lock-types",
      method: "GET",
    });
  }

  getConstr() {
    return this.fetchConstructor({
      type: "schema",
      path: "collection",
      method: "GET",
    });
  }

  getSchema(name: string, target?: string) {
    return this.fetchConstructor({
      type: "schema",
      path: "collection/" + name + (target ? "?target=" + target : ""),
      method: "GET",
    });
  }

  deleteSchema(id: string) {
    return this.fetchConstructor({
      type: "schema",
      path: "collection/" + id,
      method: "DELETE",
    });
  }

  postSinglePage(data: any) {
    return this.fetchConstructor({
      type: "schema",
      path: "pages",
      method: "POST",
      data,
    });
  }

  getSinglePages() {
    return this.fetchConstructor({
      type: "schema",
      path: "pages",
      method: "GET",
    });
  }

  getSinglePage(name: string) {
    return this.fetchConstructor({
      type: "schema",
      path: "pages/" + name,
      method: "GET",
    });
  }

  deleteSinglePage(id: string) {
    return this.fetchConstructor({
      type: "schema",
      path: "pages/" + id,
      method: "DELETE",
    });
  }

  getComponents() {
    return this.fetchConstructor({
      type: "schema",
      path: "component",
      method: "GET",
    });
  }

  getComponent(name: string) {
    return this.fetchConstructor({
      type: "schema",
      path: "component/" + name,
      method: "GET",
    });
  }

  deleteComponent(id: string) {
    return this.fetchConstructor({
      type: "schema",
      path: "component/" + id,
      method: "DELETE",
    });
  }

  postComponents(data: any) {
    return this.fetchConstructor({
      type: "schema",
      path: "component/",
      method: "POST",
      data,
    });
  }

  getData({
    typeModel,
    model,
    id,
    type,
    page,
  }: {
    typeModel: string;
    model: string;
    id?: string;
    type?: string;
    page?: number | undefined;
  }) {
    return this.fetchConstructor({
      type: "data",
      path: `${typeModel}/${model}${
        id
          ? "/" + id
          : `${type ? "?select=" + type : page ? `?page=${page}` : ""}`
      }`,
      method: "GET",
    });
  }

  getDatas({
    typeModel,
    model,
    type,
    pagin,
  }: {
    typeModel: string;
    model: string;
    type?: string;
    pagin?: any;
  }) {
    return this.fetchConstructor({
      type: "filter",
      path: `${typeModel}/${model}${type ? "?select=" + type : ""}`,
      method: "POST",
      data: { pagin },
    });
  }

  deleteDatas({
    typeModel,
    model,
    data,
  }: {
    typeModel: string;
    model: string;
    data: any;
  }) {
    return this.fetchConstructor({
      type: "data",
      path: `${typeModel}/${model}`,
      method: "DELETE",
      data,
    });
  }

  postDatas(typeModel: string, model: string, data: any) {
    return this.fetchConstructorAsJSend<{ _id: string }>({
      type: "data",
      path: `${typeModel}/${model}`,
      method: "POST",
      data,
    });
  }

  putDatas(typeModel: string, model: string, data: any) {
    return this.fetchConstructorAsJSend<{ _id: string }>({
      type: "data",
      path: `${typeModel}/${model}/${data._id}`,
      method: "PUT",
      data,
    });
  }

  filterDatas({
    typeModel,
    model,
    data,
    type,
    params,
  }: {
    typeModel: string;
    model: string;
    data?: any;
    type?: string;
    params?: Record<string, string> | undefined;
  }) {
    const processedData = removeEmptyObjectsAndReturnCopy(data);

    return this.fetchConstructorAsJSend<{
      items: object[];
      totalItems: number;
      currentPage: number;
      totalPages: number;
    }>({
      type: "filter",
      path: `${typeModel}/${model}`,
      method: "POST",
      data: processedData,
      params,
    });
  }

  getCountDatas({ typeModel, model }: { typeModel: string; model: string }) {
    return this.fetchConstructor({
      type: "count",
      path: `${typeModel}/${model}`,
      method: "GET",
    });
  }

  getMediaCount(filter: { filter: {} }) {
    return this.fetchConstructor({
      type: "media",
      path: `count`,
      method: "POST",
      data: filter,
    });
  }

  getMedia(
    filter:
      | { filter: {}; settings: { sort: {}; limit: number; skip: number } }
      | undefined,
  ) {
    return this.fetchConstructor({
      type: "get-media",

      method: "POST",
      data: filter,
    });
  }

  updMedia(data: { data: any; name: any; id: any }) {
    return this.fetchConstructor({
      type: "media",
      method: "POST",
      data,
    });
  }

  postAltMedia(data: { _id: any; alt: any }) {
    return this.fetchConstructor({
      type: "media-alt",
      method: "POST",
      data,
    });
  }

  deleteMediaBatch(data: { type: any; data: any }) {
    return this.fetchConstructor({
      type: "media",
      path: "batch",
      method: "DELETE",
      data,
    });
  }

  checkUsedMedia(data: { filter: {} }) {
    return this.fetchConstructor({
      type: "check-media",
      method: "POST",
      data,
    });
  }

  getSettingsSelect(select: string) {
    return this.fetchConstructor({
      type: "settings",
      path: select,
      method: "GET",
    });
  }

  postSettings(data: {
    menu?: never[];
    media?: {
      compress: boolean;
      compressValue: number;
      adaptive: boolean;
      adaptiveValue: {
        large: number;
        medium: number;
        small: number;
        thumbnail: number;
      };
    };
    excel?: {} | {};
  }) {
    return this.fetchConstructor({
      type: "settings",

      method: "POST",
      data,
    });
  }

  getTemplate(name: string) {
    return this.fetchConstructor({
      type: "templates",
      path: name,
      method: "GET",
    });
  }

  postTemplate(
    name: string,
    data: {
      name: any;
      main?: boolean;
      collectionData?: { nameDBCol: any; id: any };
    },
  ) {
    return this.fetchConstructor({
      type: "templates",
      path: name,
      method: "POST",
      data: data,
    });
  }

  putTemplate(name: string, data: { childs: never[] }) {
    return this.fetchConstructor({
      type: "templates",
      path: name,
      method: "PUT",
      data: data,
    });
  }

  deleteTemplate(name: string, data: any) {
    return this.fetchConstructor({
      type: "templates",
      path: name,
      method: "DELETE",
      data: data,
    });
  }

  getRoles(settings: string) {
    return this.fetchConstructor({
      type: `roles${settings ? "?" + settings : ""}`,

      method: "GET",
    });
  }

  getRolesCount() {
    return this.fetchConstructor({
      type: `roles`,
      path: "count",
      method: "GET",
    });
  }

  getRole(slug: any) {
    return this.fetchConstructor({
      type: "role",
      path: slug,
      method: "GET",
    });
  }

  getRolesActive() {
    return this.fetchConstructor({
      type: "roles-act",
      method: "GET",
    });
  }

  deleteRole(slug: any) {
    return this.fetchConstructor({
      type: "role",
      path: slug,
      method: "DELETE",
    });
  }

  postRole(data: any) {
    return this.fetchConstructor({
      type: "role",
      method: "POST",
      data,
    });
  }

  getInfoCMSs(settings: string | undefined) {
    return this.fetchConstructor({
      type: `info-cms${settings ? "?" + settings : ""}`,
      method: "GET",
    });
  }

  getInfoCMSCount() {
    return this.fetchConstructor({
      type: `info-cms`,
      path: "count",
      method: "GET",
    });
  }

  getInfoCMS(slug: string) {
    return this.fetchConstructor({
      type: "info-cms",
      path: slug,
      method: "GET",
    });
  }

  getInfoCMSCollections(ids: any[]) {
    return this.fetchConstructor({
      type: "info-cms-coll",
      method: "POST",
      data: ids,
    });
  }

  deleteInfoCMS(slug: any) {
    return this.fetchConstructor({
      type: "info-cms",
      path: slug,
      method: "DELETE",
    });
  }

  postInfoCMS(data: { cmsData: never; roleChange: boolean | never[] }) {
    return this.fetchConstructor({
      type: "info-cms",

      method: "POST",
      data,
    });
  }

  getUsers() {
    return this.fetchConstructor({
      type: "users",

      method: "GET",
    });
  }

  getUser(slug: any) {
    return this.fetchConstructor({
      type: "user",
      path: slug,
      method: "GET",
    });
  }

  deleteUser(slug: any) {
    return this.fetchConstructor({
      type: "user",
      path: slug,
      method: "DELETE",
    });
  }

  postUser(data: {
    name: string;
    username: string;
    email: string;
    phone: string;
    password: string;
    role: string;
    /**
     * @type {{slug:string, name:string}} cmsData
     */
  }) {
    return this.fetchConstructor({
      type: "user",
      method: "POST",
      data,
    });
  }

  getUserData() {
    return this.fetchConstructor({
      type: "user-data",
      method: "GET",
    });
  }

  postProfile(data: never[]) {
    return this.fetchConstructor({
      type: "profile",

      method: "POST",

      data,
    });
  }

  login(data: { login: string; password: string }) {
    return this.fetchConstructor({
      type: "login",
      cmsSlug: "",
      method: "POST",
      data,
    });
  }

  async logout(withoutRedirect?: boolean) {
    const res = await this.fetchConstructor({
      type: "logout",
      method: "GET",
      cmsSlug: "",
    });
    if (withoutRedirect) return res;
    window.location.href = "/login";
  }

  postInForModels(typeModel: any, model: any, data: never) {
    return this.fetchConstructor({
      type: "automat/infor-model",
      path: `${typeModel}/${model}`,
      method: "POST",

      data,
    });
  }

  deleteInForModels(typeModel: any, model: any, data: never) {
    return this.fetchConstructor({
      type: "automat/infor-model",
      path: `${typeModel}/${model}`,
      method: "DELETE",

      data,
    });
  }

  getWebhookSync(typeModel: any, model: any) {
    return this.fetchConstructor({
      type: "automat/webhook",
      path: `${typeModel}/${model}`,
      method: "GET",
    });
  }

  getCSVFromLink() {
    return this.fetchConstructor({
      type: "export-csv",
      method: "GET",
    });
  }

  fetch(endpointUrl: string) {
    return this.fetchConstructor({
      type: endpointUrl,
      method: "GET",
    });
  }
}

const api = new Api();

export default api;
