import { FC } from "react";

const ToggleOfficon: FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 4C12.0609 4 13.0783 4.42143 13.8284 5.17157C14.5786 5.92172 15 6.93913 15 8C15 9.06087 14.5786 10.0783 13.8284 10.8284C13.0783 11.5786 12.0609 12 11 12H8C8.62144 11.5347 9.12579 10.9309 9.47298 10.2365C9.82017 9.54212 10.0006 8.77633 10 8C10.0006 7.22367 9.82017 6.45788 9.47298 5.76351C9.12579 5.06913 8.62144 4.4653 8 4H11ZM5 12C3.93913 12 2.92172 11.5786 2.17157 10.8284C1.42143 10.0783 1 9.06087 1 8C1 6.93913 1.42143 5.92172 2.17157 5.17157C2.92172 4.42143 3.93913 4 5 4C6.06087 4 7.07828 4.42143 7.82843 5.17157C8.57857 5.92172 9 6.93913 9 8C9 9.06087 8.57857 10.0783 7.82843 10.8284C7.07828 11.5786 6.06087 12 5 12ZM0 8C0 9.32608 0.526784 10.5979 1.46447 11.5355C2.40215 12.4732 3.67392 13 5 13H11C12.3261 13 13.5979 12.4732 14.5355 11.5355C15.4732 10.5979 16 9.32608 16 8C16 6.67392 15.4732 5.40215 14.5355 4.46447C13.5979 3.52678 12.3261 3 11 3H5C3.67392 3 2.40215 3.52678 1.46447 4.46447C0.526784 5.40215 0 6.67392 0 8H0Z"
        fill="inherit"
      />
    </svg>
  );
};

export default ToggleOfficon;
