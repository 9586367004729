import { FC } from "react";

const ProfileIcon: FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="22"
        height="22"
        rx="6"
        fill="#EAEAEA"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.75 8.375C12.75 8.83913 12.5656 9.28425 12.2374 9.61244C11.9092 9.94063 11.4641 10.125 11 10.125C10.5359 10.125 10.0908 9.94063 9.76256 9.61244C9.43437 9.28425 9.25 8.83913 9.25 8.375C9.25 7.91087 9.43437 7.46575 9.76256 7.13756C10.0908 6.80937 10.5359 6.625 11 6.625C11.4641 6.625 11.9092 6.80937 12.2374 7.13756C12.5656 7.46575 12.75 7.91087 12.75 8.375ZM11 11C11.6962 11 12.3639 10.7234 12.8562 10.2312C13.3484 9.73887 13.625 9.07119 13.625 8.375C13.625 7.67881 13.3484 7.01113 12.8562 6.51884C12.3639 6.02656 11.6962 5.75 11 5.75C10.3038 5.75 9.63613 6.02656 9.14384 6.51884C8.65156 7.01113 8.375 7.67881 8.375 8.375C8.375 9.07119 8.65156 9.73887 9.14384 10.2312C9.63613 10.7234 10.3038 11 11 11ZM16.25 15.375C16.25 16.25 15.375 16.25 15.375 16.25H6.625C6.625 16.25 5.75 16.25 5.75 15.375C5.75 14.5 6.625 11.875 11 11.875C15.375 11.875 16.25 14.5 16.25 15.375ZM15.375 15.3715C15.3741 15.1562 15.2403 14.5087 14.647 13.9155C14.0765 13.345 13.0029 12.75 11 12.75C8.99625 12.75 7.9235 13.345 7.353 13.9155C6.75975 14.5087 6.62675 15.1562 6.625 15.3715H15.375Z"
        fill="#989898"
      />
    </svg>
  );
};

export default ProfileIcon;
