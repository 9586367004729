import { ChangeEvent, FC } from "react";

import styles from "./BaseToggler.module.scss";

interface BaseTogglerProps {
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  checked: boolean;
  textLabel?: string;
}

const BaseToggler: FC<BaseTogglerProps> = ({
  onChange,
  checked,
  textLabel,
}) => {
  const baseToggler = (event: ChangeEvent<HTMLInputElement>) => {
    onChange(event);
  };

  return (
    <div className={styles["wrapper"]}>
      <label className={styles["toggle-switch"]}>
        <input
          type="checkbox"
          checked={checked}
          onChange={baseToggler}
        />
        <span className={`${styles["slider"]} ${styles["round"]}`}></span>
      </label>
      {textLabel && <span className={styles["text-label"]}>{textLabel}</span>}
    </div>
  );
};

export default BaseToggler;
