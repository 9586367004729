import { FC } from "react";

const MediaIcon: FC = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="22"
        height="22"
        rx="6"
        fill="#FFD8E1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.502 7.5H5.75195C5.51989 7.5 5.29733 7.59219 5.13323 7.75628C4.96914 7.92038 4.87695 8.14294 4.87695 8.375V15.375L7.1922 13.3152C7.26372 13.244 7.35768 13.1997 7.45815 13.1898C7.55862 13.1799 7.65942 13.2051 7.74345 13.261L10.071 14.8124L13.3172 11.5661C13.3821 11.5012 13.4658 11.4583 13.5564 11.4435C13.647 11.4288 13.7399 11.4429 13.8221 11.4839L15.377 13.1875V8.375C15.377 8.14294 15.2848 7.92038 15.1207 7.75628C14.9566 7.59219 14.734 7.5 14.502 7.5ZM5.75195 6.625C5.28782 6.625 4.8427 6.80937 4.51452 7.13756C4.18633 7.46575 4.00195 7.91087 4.00195 8.375V15.375C4.00195 15.8391 4.18633 16.2842 4.51452 16.6124C4.8427 16.9406 5.28782 17.125 5.75195 17.125H14.502C14.9661 17.125 15.4112 16.9406 15.7394 16.6124C16.0676 16.2842 16.252 15.8391 16.252 15.375V8.375C16.252 7.91087 16.0676 7.46575 15.7394 7.13756C15.4112 6.80937 14.9661 6.625 14.502 6.625H5.75195ZM9.25195 10.5625C9.25195 10.7349 9.218 10.9055 9.15205 11.0648C9.08609 11.224 8.98941 11.3687 8.86753 11.4906C8.74565 11.6125 8.60097 11.7091 8.44173 11.7751C8.28249 11.8411 8.11181 11.875 7.93945 11.875C7.76709 11.875 7.59642 11.8411 7.43718 11.7751C7.27794 11.7091 7.13325 11.6125 7.01138 11.4906C6.8895 11.3687 6.79282 11.224 6.72686 11.0648C6.6609 10.9055 6.62695 10.7349 6.62695 10.5625C6.62695 10.2144 6.76523 9.88056 7.01138 9.63442C7.25752 9.38828 7.59136 9.25 7.93945 9.25C8.28755 9.25 8.62139 9.38828 8.86753 9.63442C9.11367 9.88056 9.25195 10.2144 9.25195 10.5625Z"
        fill="#E92755"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.5 5.75H16.25C16.4821 5.75 16.7046 5.84219 16.8687 6.00628C17.0328 6.17038 17.125 6.39294 17.125 6.625V13.625C17.125 13.8571 17.0328 14.0796 16.8687 14.2437C16.7046 14.4078 16.4821 14.5 16.25 14.5V15.375C16.7141 15.375 17.1592 15.1906 17.4874 14.8624C17.8156 14.5342 18 14.0891 18 13.625V6.625C18 6.16087 17.8156 5.71575 17.4874 5.38756C17.1592 5.05937 16.7141 4.875 16.25 4.875H7.5C7.03587 4.875 6.59075 5.05937 6.26256 5.38756C5.93437 5.71575 5.75 6.16087 5.75 6.625H6.625C6.625 6.39294 6.71719 6.17038 6.88128 6.00628C7.04538 5.84219 7.26794 5.75 7.5 5.75Z"
        fill="#E92755"
      />
    </svg>
  );
};

export default MediaIcon;
