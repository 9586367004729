import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./globsl";
import { ModalProvider } from "./components/modal/ModalProvider";
import { store } from "./store/store";

import "./index.css";

const root = document.getElementById("root");

if (root) {
  const rootElement = ReactDOM.createRoot(root);

  rootElement.render(
    <React.StrictMode>
      <Provider store={store}>
        <Router>
          <ModalProvider>
            <App />
          </ModalProvider>
        </Router>
      </Provider>
    </React.StrictMode>
  );

  reportWebVitals();
}
