import React from "react";
import { LazyComponent } from "./LazyComponent";
import { getOpenModalsList } from "store/selectors/modalSelectors";
import { useAppSelector } from "hooks/useAppSelector";

interface ModalProviderProps {
  children: React.ReactNode;
}

export function ModalProvider(props: ModalProviderProps) {
  const modals = useAppSelector(getOpenModalsList);

  return (
    <>
      {modals.map((filename) => (
        <LazyComponent
          key={filename}
          filename={filename}
        />
      ))}
      {props.children}
    </>
  );
}
