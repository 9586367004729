/* eslint-disable react/jsx-key */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import api from "func/api";
import $ from "jquery";
import { errorCatch } from "func/common";
import Select from "react-select";
import { customStyles } from "../common/funcs/common";
import InfoCMSFilterController from "./InfoCMSFilterController";

const defaultData = {
  name: "",
  isMain: false,
  shareCollections: [],
  copyCMS: [],
};

const defaultInformForFilter = {
  schema: [],
  nameColl: "",
  filter: { filterData: [], typeAnd: true },
};

const InfoCMS = ({ id, cms }) => {
  const [data, setData] = useState(null);
  const [{ collectionMain, roles, users, CMSs }, setDataToCreate] = useState(
    {}
  );
  const [userHaveAccess, setUserHaveAccess] = useState([]);
  const [CMSCopyChoose, setCMSCopyChoose] = useState([]);
  const [CMSCollections, setCMSCollections] = useState([]);
  const [informForFilter, setInformForFilter] = useState(
    defaultInformForFilter
  );
  const [changeIndex, setChangeIndex] = useState(null);
  const [filterOn, setFilterOn] = useState(false);
  //   const [funcSaveFilter, setFuncSaveFilter] = useState(null);

  const [canCopyRoles, setCanCopyRoles] = useState({});
  const [canCopyRolesData, setCanCopyRolesData] = useState([]);
  const [canCopyRolesChoose, setcanCopyRolesChoose] = useState(null);

  useEffect(() => {
    const canCopyRolesData = [];
    Object.keys(canCopyRoles).forEach((cmsSlug) => {
      Object.keys(canCopyRoles[cmsSlug]).forEach((collSlug) => {
        const rolesFilter = roles.filter((role) => {
          if (
            role.collectionsList[cmsSlug] &&
            role.collectionsList[cmsSlug][collSlug]
          ) {
            return true;
          }
          return false;
        });
        if (rolesFilter.length) {
          canCopyRolesData.push({
            ...canCopyRoles[cmsSlug][collSlug],
            collSlug,
            cmsSlug,
            roles: rolesFilter,
            choose: true,
          });
        }
      });
    });

    setCanCopyRolesData(canCopyRolesData);
  }, [canCopyRoles, roles]);

  const funcSaveFilter = (filterData, typeAnd) => {
    if (filterData && filterData?.length)
      data.shareCollections[changeIndex].filter = { filterData, typeAnd };
    else delete data.shareCollections[changeIndex].filter;
    setData(() => ({ ...data }));
    setChangeIndex(null);
    setInformForFilter(defaultInformForFilter);
  };

  const newSection = id === "new";

  useEffect(() => {
    api
      .getInfoCMS(id)
      .then((result) => {
        if (id !== "new") {
          setData(result);
          api
            .getInfoCMS("new")
            .then((infoFor) => {
              const cmsIds = result.copyCMS.map((copy) => copy.CMSId);
              const cmsChoose = [];
              infoFor.CMSs.forEach((cms) => {
                if (cmsIds.includes(cms._id)) {
                  cmsChoose.push(cms);
                }
              });
              cmsChoose.forEach((cms) => getCollForCMS(cms._id));
              setCMSCopyChoose((cmsChooseInfo) => [
                ...cmsChooseInfo,
                ...cmsChoose,
              ]);
              setDataToCreate(infoFor);
            })
            .catch((err) => errorCatch(err));
        } else {
          setData(defaultData);
          setDataToCreate(result);
        }
      })
      .catch((err) => {
        errorCatch(err);
      });
  }, [id]);

  const getCollForCMS = (id) => {
    api
      .getInfoCMSCollections([id])
      .then((result) => {
        setCMSCollections((CMSCollections) => [...CMSCollections, result]);
      })
      .catch((err) => {
        errorCatch(err);
      });
  };

  const checkedCopyCMSColl = {};

  console.log("canCopyRolesData :>> ", canCopyRolesData);
  console.log("canCopyRoles :>> ", canCopyRoles);

  data?.copyCMS?.forEach((copy, i) => {
    checkedCopyCMSColl[copy.CMSId] = {
      collections: copy.collections.map((coll) => coll.id),
      index: i,
    };
  });

  console.log("informForFilter :>> ", informForFilter);
  console.log("data :>> ", data);

  return (
    data !== null && (
      <main className="main">
        <div className="container-fluid">
          <h4 className="mb-4 mt-4">{newSection ? "Новая CMS" : data.name}</h4>
          <div className="row">
            <div className="col-8">
              <div className="card rounded p-3 mb-4">
                <h5>Общие настройки</h5>
                <div className="input-group mb-3">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                  >
                    Название
                  </span>
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Администратор"
                    value={data.name}
                    onChange={(e) => {
                      data.name = e.target.value;
                      setData({ ...data });
                    }}
                  />
                </div>
                <div className="input-group">
                  <span
                    className="input-group-text"
                    id="basic-addon1"
                  >
                    Статус
                  </span>
                  <Select
                    options={[
                      { label: "Основной", value: true },
                      { label: "Дополнительная", value: false },
                    ]}
                    styles={customStyles}
                    className="flex-grow-1"
                    value={
                      data.isMain
                        ? { label: "Основной", value: true }
                        : { label: "Дополнительная", value: false }
                    }
                    onChange={({ value, label }) => {
                      data.isMain = value;
                      setData({ ...data });
                    }}
                  ></Select>
                </div>
              </div>
              {collectionMain ? (
                <div className="card p-3 mb-4 container">
                  <h5 className="mb-3">Ссылаться на элементы из главной CMS</h5>
                  <div className=" row row-cols-3">
                    {collectionMain.map((coll, collI) => {
                      let index = null;
                      const haveInShareColl = data.shareCollections.find(
                        (sh, i) => {
                          if (sh.collectionID === coll._id) {
                            index = i;
                            return true;
                          }
                          return false;
                        }
                      );
                      return (
                        <div className="mb-3 col">
                          <input
                            type="checkbox"
                            id={"check" + collI}
                            checked={index !== null}
                            className="form-check-input invisible"
                            style={{ display: "none" }}
                            onChange={({ target }) => {
                              if (data.shareCollections) {
                                if (target.checked)
                                  data.shareCollections.push({
                                    collectionID: coll._id,
                                  });
                                else {
                                  let i = null;
                                  data.shareCollections.find((sh, index) => {
                                    if (sh.collectionID === coll._id) {
                                      i = index;
                                      return true;
                                    }
                                    return false;
                                  });
                                  data.shareCollections.splice(i, 1);
                                }
                              } else
                                data.shareCollections = [
                                  { collectionID: coll._id },
                                ];

                              setData(() => ({ ...data }));
                            }}
                          />
                          <label
                            htmlFor={"check" + collI}
                            className={`pointer text-decoration-underline ${
                              index !== null ? "text-primary" : ""
                            }`}
                          >
                            {coll.name}
                          </label>
                          {index !== null && (
                            <i className="bi bi-check2 fs-6 text-primary ms-1"></i>
                          )}
                          {index !== null && (
                            <i
                              className={`bi bi-filter-square${
                                haveInShareColl?.filter?.filterData
                                  ? "-fill"
                                  : ""
                              } ms-2 text-primary`}
                              onClick={() => {
                                setInformForFilter(() => ({
                                  schema: coll.body,
                                  nameColl: coll.name,
                                  filter:
                                    haveInShareColl?.filter?.filterData ??
                                    defaultInformForFilter.filter.filterData,
                                  typeAnd:
                                    haveInShareColl?.filter?.typeAnd ??
                                    defaultInformForFilter.filter.typeAnd,
                                }));
                                setChangeIndex(index);
                                setFilterOn(true);
                              }}
                            ></i>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              {CMSs ? (
                <div className="card p-3 mb-4 ">
                  <h5 className="mb-3">Скопировать структуру из других CMS</h5>
                  {CMSCopyChoose.map((CMS, i) => {
                    return (
                      <div
                        className="card p-3 mb-4 container"
                        style={{ backgroundColor: "#F4F4F4" }}
                      >
                        <h6 className="mb-4">{CMS.name}</h6>
                        <div className="row row-cols-3">
                          {CMSCollections[i]
                            ? CMSCollections[i].map((coll, collI) => {
                                const isChoose =
                                  checkedCopyCMSColl[CMS._id] &&
                                  checkedCopyCMSColl[
                                    CMS._id
                                  ].collections.includes(coll._id);
                                return (
                                  <div className="mb-3 col">
                                    <input
                                      type="checkbox"
                                      id={"check" + CMS.name + collI}
                                      checked={isChoose}
                                      style={{ display: "none" }}
                                      className="form-check-input invisible"
                                      onChange={({ target }) => {
                                        if (data.copyCMS) {
                                          if (checkedCopyCMSColl[CMS._id]) {
                                            if (target.checked)
                                              data.copyCMS[
                                                checkedCopyCMSColl[CMS._id]
                                                  .index
                                              ].collections.push({
                                                id: coll._id,
                                                add: false,
                                              });
                                            else {
                                              data.copyCMS[
                                                checkedCopyCMSColl[CMS._id]
                                                  .index
                                              ].collections.splice(
                                                checkedCopyCMSColl[
                                                  CMS._id
                                                ].collections.indexOf(coll._id),
                                                1
                                              );
                                            }
                                          } else {
                                            data.copyCMS.push({
                                              collections: [
                                                { id: coll._id, add: false },
                                              ],
                                              CMSId: CMS._id,
                                            });
                                          }
                                        } else
                                          data.copyCMS = [
                                            {
                                              collections: [
                                                { id: coll._id, add: false },
                                              ],
                                              CMSId: CMS._id,
                                            },
                                          ];

                                        if (target.checked) {
                                          if (!canCopyRoles[CMS.slug])
                                            canCopyRoles[CMS.slug] = {};

                                          canCopyRoles[CMS.slug][coll.slug] = {
                                            name: coll.name,
                                          };
                                          setCanCopyRoles({ ...canCopyRoles });
                                          setcanCopyRolesChoose(null);
                                        } else {
                                          if (
                                            canCopyRoles[CMS.slug] &&
                                            canCopyRoles[CMS.slug][coll.slug]
                                          ) {
                                            delete canCopyRoles[CMS.slug][
                                              coll.slug
                                            ];
                                            setCanCopyRoles({
                                              ...canCopyRoles,
                                            });
                                            setcanCopyRolesChoose(null);
                                          }
                                        }

                                        setData(() => ({ ...data }));
                                      }}
                                    />
                                    <label
                                      htmlFor={"check" + CMS.name + collI}
                                      className={`pointer text-decoration-underline ${
                                        isChoose ? "text-primary" : ""
                                      }`}
                                    >
                                      {coll.name}
                                    </label>
                                    {isChoose && (
                                      <i className="bi bi-check2 fs-6 text-primary ms-1"></i>
                                    )}
                                  </div>
                                );
                              })
                            : null}
                        </div>
                      </div>
                    );
                  })}

                  <div
                    className="btn-group "
                    role="group"
                  >
                    <button
                      id="btnGroupDrop1"
                      type="button"
                      className="btn btn-primary w-100 dropdown-toggle d-flex justify-content-between"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      Веберите CMS
                      <i className="bi bi-caret-down-fill"></i>
                    </button>
                    <ul
                      className="dropdown-menu w-100"
                      aria-labelledby="btnGroupDrop1"
                    >
                      {CMSs.map((CMS) => {
                        if (
                          data._id !== CMS._id &&
                          !CMSCopyChoose.map(
                            (cmsChoose) => cmsChoose._id
                          ).includes(CMS._id)
                        )
                          return (
                            <li>
                              <button
                                className="dropdown-item"
                                onClick={(e) => {
                                  setCMSCopyChoose((choose) => [
                                    ...choose,
                                    CMS,
                                  ]);
                                  getCollForCMS(CMS._id);
                                }}
                              >
                                {CMS.name}
                              </button>
                            </li>
                          );
                      })}
                    </ul>
                  </div>
                </div>
              ) : null}
              {roles && users ? (
                <div className="card p-3 mb-4">
                  <h6>Предоставить доступ</h6>
                  <div className="d-flex align-items-center mb-3 flex-wrap">
                    {users.map((user, userI) => (
                      <div className="me-3 mb-3">
                        <input
                          type="checkbox"
                          id={"check" + userI}
                          checked={userHaveAccess.includes(user._id)}
                          className="me-1 form-check-input"
                          onChange={({ target }) => {
                            if (target.checked) userHaveAccess.push(user._id);
                            else
                              userHaveAccess.splice(
                                userHaveAccess.indexOf(user._id),
                                1
                              );

                            setUserHaveAccess(() => [...userHaveAccess]);
                          }}
                        />
                        <label htmlFor={"check" + userI}>{user.name}</label>
                      </div>
                    ))}
                  </div>
                </div>
              ) : null}
            </div>
            <div className="col-4">
              <div className=" card p-3 mb-4">
                <button
                  className="btn btn-primary mb-2"
                  onClick={(e) => {
                    console.log("data in send", data);
                    api
                      .postInfoCMS({
                        cmsData: data,
                        roleChange: canCopyRolesChoose
                          ? canCopyRolesData
                          : false,
                      })
                      .then((state) => {
                        if (newSection) {
                          window.location.pathname = `/front/${cms}/settings/info-cms/${state.slug}/`;
                        } else {
                          $(e.target).addClass("bg-success");
                          setTimeout(() => {
                            $(e.target).removeClass("bg-success");
                          }, 2500);
                          setData({ ...state });
                        }
                      })
                      .catch((err) => {
                        $(e.target).addClass("bg-danger");
                        setTimeout(() => {
                          $(e.target).removeClass("bg-danger");
                        }, 2500);
                        errorCatch(err);
                      });
                  }}
                >
                  Сохранить
                </button>
                <button className="btn btn-light mb-2">Отменить и выйти</button>
                <p className="text-secondary m-0 fs-14">
                  Правлено: 01.12.2020 12:10 (danil)
                </p>
              </div>

              {canCopyRolesChoose === null && canCopyRolesData.length > 0 && (
                <div className=" card p-3 mb-4">
                  <h6>Скопировать настройки для ролей?</h6>
                  <p style={{ fontSize: "14px" }}>
                    Для части копируемых коллекций и страниц обнаружены
                    индивидуальные настройки ролей. Отметьте коллекции для
                    которых вы хотите скопировать настройки доступа ролей.
                  </p>

                  {canCopyRolesData.map((copy, collI) => {
                    return (
                      <div className="me-3 mb-3">
                        <input
                          type="checkbox"
                          id={"check" + collI}
                          checked={copy.choose}
                          className="form-check-input me-2"
                          onChange={({ target }) => {
                            canCopyRolesData[collI].choose = target.checked;

                            setCanCopyRolesData([...canCopyRolesData]);
                          }}
                        />
                        <label htmlFor={"check" + collI}>
                          {copy.name}
                          <span className="text-secondary ms-2">
                            ({copy.roles.map((role) => role.name).join(", ")})
                          </span>
                        </label>
                      </div>
                    );
                  })}
                  <button
                    className="btn btn-primary mb-2"
                    onClick={() => setcanCopyRolesChoose(true)}
                  >
                    Скопировать
                  </button>
                  <button
                    className="btn btn-light"
                    onClick={() => setcanCopyRolesChoose(false)}
                  >
                    Оставить дефолтные
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <InfoCMSFilterController
          {...informForFilter}
          setFilters={funcSaveFilter}
          filterOn={filterOn}
          setFilterOn={setFilterOn}
        />
      </main>
    )
  );
};

export default InfoCMS;
