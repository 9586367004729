import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  flexRender,
  useReactTable,
  getCoreRowModel,
} from "@tanstack/react-table";

import Row from "components/Table/Row";
import ActionCell, { ContextMenuOption } from "components/Table/ActionCell";

import type {
  CellContext,
  ColumnDef,
  Row as ReactTableRowType,
} from "@tanstack/react-table";

import { hasVerticalScrollbar } from "utils/hasVerticalScrollbar";
import { getScrollbarWidth } from "utils/getScrollbarWidth";

import styles from "./Table.module.scss";

type TableProps<TData> = {
  columns: ColumnDef<TData>[];
  data: TData[];
  contextMenuOptions?: ContextMenuOption[];
};

interface ActionCellProps<TData> extends CellContext<TData, any> {
  activeRow: ReactTableRowType<TData>;
  setActiveRow: Dispatch<SetStateAction<ReactTableRowType<TData> | null>>;
}

const scrollBarWidth = getScrollbarWidth();

const Table = <TData,>({
  columns,
  data,
  contextMenuOptions = [],
}: TableProps<TData>) => {
  const columnsWithAction = useMemo(() => {
    if (data.length > 0) {
      return [
        ...columns,
        {
          accessorKey: "actions",
          header: "",
          cell: ({ row, activeRow, setActiveRow }: ActionCellProps<TData>) => {
            return (
              <ActionCell<TData>
                row={row}
                activeRow={activeRow}
                setActiveRow={setActiveRow}
                contextMenuOptions={contextMenuOptions}
              />
            );
          },
        },
      ];
    }

    return [];
  }, [data, columns, contextMenuOptions]);

  const table = useReactTable<TData>({
    data,
    columns: columnsWithAction as ColumnDef<TData>[],
    getCoreRowModel: getCoreRowModel(),
  });

  const gridTemplateColumns = `minmax(320px, 100%) ${columnsWithAction
    .map(() => "140px")
    .slice(1, -1)
    .toString()
    .replace(/,/g, " ")} 38px`;

  // Checking scroll bar existence
  const listWrapperRef = useRef<HTMLDivElement>(null);
  const [withScrollbar, setWithScrollbar] = useState(true);

  const checkForScrollbar = () => {
    if (listWrapperRef.current) {
      setWithScrollbar(hasVerticalScrollbar(listWrapperRef.current));
    }
  };

  // Initialize ResizeObserver
  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      for (let entry of entries) {
        checkForScrollbar();
      }
    });

    if (listWrapperRef.current) {
      resizeObserver.observe(listWrapperRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [data]);

  if (data.length === 0) {
    return null;
  }

  return (
    <>
      {table.getHeaderGroups().map((headerGroup) => (
        <div
          key={headerGroup.id}
          className={`${styles["row"]} ${styles["header-row"]}`}
          style={{
            gridTemplateColumns,
            paddingRight: withScrollbar ? scrollBarWidth : 0,
          }}
        >
          {headerGroup.headers.map((header) => {
            return (
              <div
                key={header.id}
                className={styles["cell"]}
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </div>
            );
          })}
        </div>
      ))}
      <div
        ref={listWrapperRef}
        style={{ height: "calc(100vh - 210px)", overflowY: "auto" }}
      >
        {table.getRowModel().rows.map((row) => {
          return (
            <Row
              key={row.id}
              row={row}
              gridTemplateColumns={gridTemplateColumns}
            />
          );
        })}
      </div>
    </>
  );
};

export default Table;
