import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { SERVER_URL, API_VERSION } from "func/constants";
import { APIKey } from "pages/Apikeys/Apikeys";
import { APIResponse } from "typings/apiState";

type CreateApiKeyPayload = {
  id: string;
  key: string;
};

const baseURL = `${SERVER_URL}/api/${API_VERSION}/main/api-keys`;

export const apiKeyApiService = createApi({
  reducerPath: "apiKeyApiService",
  baseQuery: fetchBaseQuery({
    baseUrl: baseURL,
    prepareHeaders(headers) {
      return headers;
    },
    credentials: "include",
  }),
  tagTypes: ["Key"],
  endpoints: (builder) => ({
    // endpoint for fetching collection elements
    createApiKey: builder.mutation<APIResponse<CreateApiKeyPayload>, void>({
      query: () => ({
        url: "/",
        method: "POST",
      }),
      invalidatesTags: ["Key"],
    }),
    fetchApiKeys: builder.query<
      APIResponse<{
        items: APIKey[];
        totalItems: number;
        totalPages: number;
        currentPage: number;
      }>,
      { params: { page: number; limit: number } }
    >({
      query: ({ params: { page, limit } }) => ({
        url: "/",
        method: "GET",
        params: {
          page,
          limit,
        },
      }),
      providesTags: ["Key"],
    }),
    updateApiKeyName: builder.mutation<
      APIResponse<CreateApiKeyPayload>,
      { id: string; name: string }
    >({
      query: ({ id, name }: { id: string; name: string }) => ({
        url: `/${id}`,
        method: "PATCH",
        body: { name },
      }),
      invalidatesTags: ["Key"],
    }),
    deleteApiKey: builder.mutation<
      APIResponse<CreateApiKeyPayload>,
      { id: string }
    >({
      query: ({ id }: { id: string }) => ({
        url: `/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: ["Key"],
    }),
  }),
});

export const {
  useCreateApiKeyMutation,
  useFetchApiKeysQuery,
  useUpdateApiKeyNameMutation,
  useDeleteApiKeyMutation,
} = apiKeyApiService;
